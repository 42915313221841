import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import persona1 from "../../assets/images/persona1.png";
import dish from "../../assets/images/dish.png";
import dish1 from "../../assets/images/dish1.png";
import dish2 from "../../assets/images/dish2.png";
import persona from "../../assets/images/persona.png";
import persona2 from "../../assets/images/persona2.png";
import persona3 from "../../assets/images/persona3.png";
import personaa from "../../assets/images/personaa.png";
import personaa1 from "../../assets/images/personaa1.png";
import personaa3 from "../../assets/images/personaa3.png";


interface ReceipeProps {
  setSelectedPersona: (index: number) => void;
  id:any;
  getInitialSlide:(id:string)=>void;
}
const Persona: React.FC<ReceipeProps> = ({
  setSelectedPersona, id, getInitialSlide
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: getInitialSlide(id),
    afterChange: (currentSlide: number) => {
      setSelectedPersona(currentSlide); // Update selectedPersona when slide changes
    }
  };

  
  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryCode, setCountryCode] = useState<string | null>(null);

  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    const storedCode = localStorage.getItem('countryCode');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      setCountryCode(storedCode);
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // getPersonaRecipeDetails();
    }
  }, [selectedCountry]);

  const personaImg = selectedCountry === 'India' ? persona : selectedCountry === 'UK' ? personaa : null;
  const personaImg1 = selectedCountry === 'India' ? persona1 : selectedCountry === 'UK' ? personaa1 : null;
  const personaImg2 = selectedCountry === 'India' ? persona2 : selectedCountry === 'UK' ? personaa : null;
  const personaImg3 = selectedCountry === 'India' ? persona3 : selectedCountry === 'UK' ? personaa3 : null;

  return (
    // <div className="max-w-7xl mx-auto p-4 pb-12">
    <div className="mx-auto xl:p-14 lg:p-10 md:p-8 p-5 pb-12">
      <Slider {...settings}>
        <div className="flex items-center lg:pb-20 md:pb-16 pt-5 bg-gradient-to-r from-white to-[#C93B3B]/[2%] rounded-[20px] shadow-[0px_10px_0px_0px_rgba(201,59,59,0.2)] overflow-hidden border border-[rgba(255,184,0,0.2)] px-5 lg:h-[370px] md:h-[280px] h-[200px] w-full">
          <h1 className="font-sans lg:text-2xl font-bold pb-6 text-md">
            Explore Recipe Kit crafted just for you.
          </h1>
          <div
            className="bg-cover bg-center rounded-[20px] xl:h-full lg:h-full md:h-full h-[100px] pb-2 justify-end w-full flex"
            style={{ backgroundImage: `url(${personaImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
          >
            <div className="w-1/2 xl:px-40 lg:pl-40 md:pl-28 pl-16 lg:py-8 md:py-2 py-2">
              <div>
                <h2 className="lg:text-md md:text-xs md:pt-1 text-[6px] font-sans font-normal text-[#C30F16]">RECIPE FOR</h2>
                <h3 className="lg:text-2xl md:text-xl text-[10px] font-bold font-sans text-[#C30F16] xl:mt-2 lg:mt-2 md:mt-1 mt-1">
                  United Family
                </h3>
                <ul className="xl:mt-6 lg:mt-6 md:mt-3 mt-1">
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-2 mr-1">
                      <img src={dish1} className="w-[14px] xl:w-full lg:w-full md:w-full"/>
                    </span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                      Nutritious recipes for busy professionals
                    </span>
                  </li>
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-2 mr-1">
                      <img src={dish2} className="w-[14px] xl:w-full lg:w-full md:w-full"/></span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                      Create memorable dining experiences
                    </span>
                  </li>
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-1 mr-1"><img src={dish} className="w-[12px] xl:w-full lg:w-full md:w-full"/></span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                      Nourish more than just the body
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center lg:pb-20 md:pb-16 pt-5 bg-gradient-to-r from-white to-[#C93B3B]/[2%] rounded-[20px] shadow-[0px_10px_0px_0px_rgba(201,59,59,0.2)] overflow-hidden border border-[rgba(255,184,0,0.2)] px-5 lg:h-[370px] md:h-[280px] h-[200px] w-full">
          <h1 className="font-sans lg:text-2xl font-bold pb-6 text-md">
            Explore Recipe Kit crafted just for you.
          </h1>
          <div
            className="bg-cover bg-center rounded-[20px] xl:h-full lg:h-full md:h-full h-[100px] pb-2 justify-end w-full flex"
            style={{ backgroundImage: `url(${personaImg1})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
          >
            <div className="w-1/2 xl:px-40 lg:pl-40 md:pl-28 pl-16 lg:py-8 md:py-2 py-2">
              <div>
                <h2 className="lg:text-md md:text-xs md:pt-1 text-[6px] font-sans font-normal text-[#C30F16]">RECIPE FOR</h2>
                <h3 className="lg:text-2xl md:text-xl text-[9px] font-bold font-sans text-[#C30F16] xl:mt-2 lg:mt-2 md:mt-1 mt-1">
                Working Professional
                </h3>
                <ul className="xl:mt-6 lg:mt-6 md:mt-3 mt-1">
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-2 mr-1">
                      <img src={dish1} className="w-[14px] xl:w-full lg:w-full md:w-full"/>
                    </span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    Nutritious recipes for Busy Professionals
                    </span>
                  </li>
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-2 mr-1">
                      <img src={dish2} className="w-[14px] xl:w-full lg:w-full md:w-full"/></span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    Create memorable Dining Experiences
                    </span>
                  </li>
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-1 mr-1"><img src={dish} className="w-[12px] xl:w-full lg:w-full md:w-full"/></span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    Nourish more than just the Body
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center lg:pb-20 md:pb-16 pt-5 bg-gradient-to-r from-white to-[#C93B3B]/[2%] rounded-[20px] shadow-[0px_10px_0px_0px_rgba(201,59,59,0.2)] overflow-hidden border border-[rgba(255,184,0,0.2)] px-5 lg:h-[370px] md:h-[280px] h-[200px] w-full">
          <h1 className="font-sans lg:text-2xl font-bold pb-6 text-md">
            Explore Recipe Kit crafted just for you.
          </h1>
          <div
            className="bg-cover bg-center rounded-[20px] xl:h-full lg:h-full md:h-full h-[100px] pb-2 justify-end w-full flex"
            style={{ backgroundImage: `url(${personaImg2})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
          >
            <div className="w-1/2 xl:px-40 lg:pl-40 md:pl-28 pl-16 lg:py-8 md:py-2 py-2">
              <div>
                <h2 className="lg:text-md md:text-xs md:pt-1 text-[6px] font-sans font-normal text-[#C30F16]">RECIPE FOR</h2>
                <h3 className="lg:text-2xl md:text-xl text-[10px] font-bold font-sans text-[#C30F16] xl:mt-2 lg:mt-2 md:mt-1 mt-1">
                Solution Seekers
                </h3>
                <ul className="xl:mt-6 lg:mt-6 md:mt-3 mt-1">
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-2 mr-1">
                      <img src={dish1} className="w-[12px] xl:w-full lg:w-full md:w-full"/>
                    </span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    Embracing proactive steps
                    </span>
                  </li>
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-4 mr-1">
                      <img src={dish2} className="w-[14px] xl:w-full lg:w-full md:w-full"/></span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    Fostering meaningful dining experiences

                    </span>
                  </li>
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-2 mr-1"><img src={dish} className="w-[12px] xl:w-full lg:w-full md:w-full"/></span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    Nourishing both body and soul
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center lg:pb-20 md:pb-16 pt-5 bg-gradient-to-r from-white to-[#C93B3B]/[2%] rounded-[20px] shadow-[0px_10px_0px_0px_rgba(201,59,59,0.2)] overflow-hidden border border-[rgba(255,184,0,0.2)] px-5 lg:h-[370px] md:h-[280px] h-[200px] w-full">
          <h1 className="font-sans lg:text-2xl font-bold pb-6 text-md">
            Explore Recipe Kit crafted just for you.
          </h1>
          <div
            className="bg-cover bg-center rounded-[20px] xl:h-full lg:h-full md:h-full h-[100px] pb-2 justify-end w-full flex"
            style={{ backgroundImage: `url(${personaImg3})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
          >
            <div className="w-1/2 xl:px-40 lg:pl-40 md:pl-28 pl-16 lg:py-8 md:py-2 py-2">
              <div>
                <h2 className="lg:text-md md:text-xs md:pt-1 text-[6px] font-sans font-normal text-[#C30F16]">RECIPE FOR</h2>
                <h3 className="lg:text-2xl md:text-xl text-[10px] font-bold font-sans text-[#C30F16] xl:mt-2 lg:mt-2 md:mt-1 mt-1">
                Fitness Enthusiasts
                </h3>
                <ul className="xl:mt-6 lg:mt-6 md:mt-3 mt-1">
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-3 mr-1">
                      <img src={dish1} className="w-[14px] xl:w-full lg:w-full md:w-full"/>
                    </span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    New solutions for proactive people
                    </span>
                  </li>
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-2 mr-1">
                      <img src={dish2} className="w-[14px] xl:w-full lg:w-full md:w-full"/></span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    Fostering rewarding experiences
                    </span>
                  </li>
                  <li className="flex items-center justify-start xl:mt-4 lg:mb-4 md:mb-2 mb-1">
                    <span className="xl:mr-2 lg:mr-2 md:mr-1 mr-1"><img src={dish} className="w-[12px] xl:w-full lg:w-full md:w-full"/></span>
                    <span className="text-[#313131] xl:text-sm lg:text-sm md:text-xs text-[6px] pl-2 font-sans">
                    Promoting overall growth
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Persona;
