import React, { useEffect, useState } from "react";
import Foke from "../../../src/assets/images/foke.png";
import Clock from "../../../src/assets/images/Clock.png";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import {
  getByPersonaAsync,
  getRecipeByTimeAsync,
} from "../../services/reducers/reciepeReducer";
import GallerySection from "./SameDishes";

interface FoodItemProps {
  image: string;
  category: string;
  title: string;
  description: string;
  price: string;
  time: string;
  serves: string;
}

const FoodItem: React.FC<FoodItemProps> = ({
  image,
  category,
  title,
  description,
  price,
  time,
  serves,
}) => {
  return (
    <div className="w-full md:max-w-md mx-auto flex flex-row md:block lg:block xl:block">
      <img
        src={image}
        alt={title}
        className="lg:w-[400px] lg:h-[180px] xl:w-[450px] xl:h-[260px] md:h-[130px] md:w-[400px] h-[120px] w-[120px] max-w-full object-cover mt-3 mb-1 lg:mb-2 rounded-xl"
      />
      <div className="flex flex-col items-start space-y-1 p-3 md:block lg:block xl:block">
        <span className="text-gray-400 font-sans text-sm lg:text-md xl:inline-block lg:inline-block md:inline-block hidden">
          {category}
        </span>
        <h3 className="font-bold pt-1 lg:py-2 font-sans md:text-md lg:text-lg text-xl tracking-wide md:text-sm line-clamp-1">
          {title}
        </h3>
        <p
          className={`text-[12px] lg:text-md font-sans line-clamp-1 ${description?.length > 10 ? "min-h-[10px]" : ""
            }`}
        >
          {description}
        </p>

        <div className="flex justify-start items-center mt-auto gap-8 md:gap-3 lg:py-5 md:py-4 pt-3 pb-1">
          <div className="flex items-center space-x-1 lg:space-x-1">
            <div
              className="lg:w-6 lg:h-6 md:w-4 md:h-4 w-4 h-4 bg-cover bg-no-repeat"
              style={{ backgroundImage: `url(${Foke})` }}
            ></div>
            <span className="text-[#2C2C2C] text-[10px] xl:text-lg lg:text-md md:text-xs font-medium font-sans">
              {serves}
            </span>
          </div>

          <div className="flex items-center space-x-1 lg:space-x-1">
            <div
              className="lg:w-6 lg:h-6 md:w-4 md:h-4 w-4 h-4 bg-cover bg-no-repeat"
              style={{ backgroundImage: `url(${Clock})` }}
            ></div>
            <span className="text-[#2C2C2C] text-[10px] xl:text-lg lg:text-md md:text-xs font-medium font-sans">
              {time}
            </span>
          </div>
        </div>
        <div className="w-2/3 md:w-full p-2 md:p-4">
          <div className="flex flex-col">
            {/* Title and description aligned with image top */}
            <div>
              <h3 className="font-bold font-sans text-md lg:text-lg -mt-1">
                {title}
              </h3>
              <p className={`text-[12px] lg:text-md font-sans line-clamp-3 mb-3 md:line-clamp-4 text-[#313131] ${
                description?.length > 10 ? "md:min-h-[68px]" : ""
              }`}>
                {description}
              </p>
            </div>

            {/* Icons and info row */}
            <div className="flex flex-col mt-2 md:mt-auto">
              <div className="flex justify-start space-x-4 md:space-x-0 md:justify-between">
                <div className="flex items-center space-x-1 lg:space-x-2">
                  <div
                    className="lg:w-8 lg:h-8 md:w-5 md:h-5 w-4 h-4 bg-cover bg-no-repeat"
                    style={{ backgroundImage: `url(${Foke})` }}
                  ></div>
                  <span className="text-[#2C2C2C] text-[10px] lg:text-lg md:text-sm  font-sans">
                    {serves}
                  </span>
                </div>

                <div className="flex items-center space-x-1 lg:space-x-2">
                  <div
                    className="lg:w-8 lg:h-8 md:w-5 md:h-5 w-4 h-4 bg-cover bg-no-repeat"
                    style={{ backgroundImage: `url(${Clock})` }}
                  ></div>
                  <span className="text-[#2C2C2C] text-[10px] lg:text-lg md:text-sm  font-sans">
                    {time}
                  </span>
                </div>
              </div>

              {/* Category and price row */}
              <div className="flex justify-between items-center mt-1">
                <span className="text-[#9B9B9B] font-sans text-xs lg:text-md uppercase">
                  {category}
                </span>
                <span className="text-black font-sans text-sm lg:text-md font-bold md:hidden">
                  {price}
                </span>
              </div>
            </div>
          </div>

          {/* Desktop price button */}
          <button className="hidden md:block bg-[#0B6E27] text-xs lg:text-lg md:text-md text-white px-5 lg:px-4 md:px-4 py-2 mt-4 w-full rounded-lg font-sans">
            Recipe kit from {price} • 1 Day Delivery{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

interface ReceipeProps {
  selectedRecipe?: string;
  type: string;
  selectedPersona?: number;
  personaDetails?: any;
  displayedIds?: any;
}

const ReceipeKit: React.FC<ReceipeProps> = ({
  selectedRecipe,
  type,
  selectedPersona,
  personaDetails,
  displayedIds,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [receipeDetails, setReceipeDetails] = useState<any>([]);
  const [displayedRecipeIds, setDisplayedRecipeIds] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (type === "Home") {
      getRecipeDetails();
    }
  }, [selectedRecipe, type]);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const storedCountry = localStorage.getItem('selectedCountry');
  const storedCode = localStorage.getItem('countryCode');
  useEffect(() => {
 
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      setCountryCode(storedCode);
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      getRecipeDetails();
    }
  }, [selectedCountry]);

  const getRecipeDetails = () => {
    if (countryCode === "IN" || countryCode === "UK") {
    const formattedRecipe =
      selectedRecipe &&
      selectedRecipe.charAt(0).toLowerCase() + selectedRecipe.slice(1);
    if (formattedRecipe) {
      localStorage.setItem("formattedRecipe", formattedRecipe);
    }
   
      const request = {
        time: formattedRecipe,
        country: countryCode,
      };
      console.log("Requesting recipes for country:", countryCode);

      dispatch(getRecipeByTimeAsync(request)).then((res) => {
        console.log("API Response:", res.payload);
        if (res.payload) {
          setReceipeDetails(res.payload);
          const ids = res.payload?.data?.slice(0, 3).map((item: any) => item.id);
          setDisplayedRecipeIds(ids);
        }
      });
    }
  };

  const handleView = (id: string) => {
    navigate(`/detail/${id}`);
  };

  const getNumberOfItemsToShow = () => {
    return isMobile ? 2 : 3;
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 gap-4 md:gap-8 px-4 md:px-6 pb-2"> {/*here is the padding between sale and the container###############*/}
        {type === "Home" &&
          receipeDetails?.data?.slice(0, getNumberOfItemsToShow()).map((item: any, index: number) => (
            <div key={index} className="cursor-pointer">
              <div onClick={() => handleView(item.id)}>
                <FoodItem
                  image={item.images[0]}
                  category={`${capitalizeFirstLetter(item.cuisine)} Cuisine`}
                  title={item.name}
                  description={item.description}
                  price={`₹${parseInt(item.base_price)}`}
                  time={`Time: ${item.cook_time.minutes
                      ? `${item.cook_time.minutes} min`
                      : `${item.cook_time.hours} hour${item.cook_time.hours > 1 ? "s" : ""
                      }`
                    }`}
                  // time={`Time: ${item.cook_time.minutes} min`}
                  serves="Serves: 2-3"
                />
              </div>
              <GallerySection recipeIds={[item.id]} countryCode={countryCode}/>
            </div>
          ))}
        {type === "Persona" &&
          personaDetails?.data?.slice(0, getNumberOfItemsToShow()).map((item: any, index: number) => (
            <div key={index} className="cursor-pointer">
              <div onClick={() => handleView(item.id)}>
                <FoodItem
                  image={item.images[0]}
                  category={`${capitalizeFirstLetter(item.cuisine)} Cuisine`}
                  title={item.name}
                  description={item.description}
                  price={`₹${parseInt(item.base_price)}`}
                  time={`Time: ${item.cook_time.minutes
                      ? `${item.cook_time.minutes} min`
                      : `${item.cook_time.hours} hour${item.cook_time.hours > 1 ? "s" : ""
                      }`
                    }`}
                  serves="Serves: 2-3"
                />
              </div>
              <GallerySection recipeIds={[item.id]} countryCode={countryCode}/>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReceipeKit;