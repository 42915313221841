import axios from "axios";
import { API_URL } from "../sharedService/constants";
import { http } from "../sharedService/httpService";

// interface CreateCodRequest {
//   orderId: any;
// }

export function getRecipeByTime(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}recipe/get-by-time?time=${reqBody?.time}&country=${reqBody?.country}`
  );
}

export function getRecipeIngredient(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}recipe/get-rec/${reqBody?.id}?country=${reqBody?.country}`
  );
}

export function getRecipeById(id: string) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}recipe/get-by-id/${id}`);
}

export function createByStripe(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-stripe-order`,
    reqBody
  );
}

export function createByRazorpay(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-razorpay-order`,
    reqBody
  );
}

export function getSearchAll(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}shop/searchAll?term=${reqBody?.term}&country=${reqBody?.country}`
  );
}

export function getCatCousine(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}shop/cat?cuisine=${reqBody?.cuisine}&country=${reqBody?.country}`
  );
}

export function getSubCatByCousineCat(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}shop/sub-cat?cuisine=${reqBody?.cuisine}&country=${reqBody?.country}&category=${reqBody?.category}`
  );
}

export function addUpdateItem(id: string, reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}cart/${id}`, reqBody);
}

export function getItemsById(id: string, country: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}cart/${id}?country=${country}`
  );
}

export function createOrder(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}order/create-order`, reqBody);
}

export function getAdressByUserId(id: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}user/fetchAll/${id}`);
}

export function addAddress(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}user/add-address`, reqBody);
}

export function getOrderByUserId(id: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}order/user/${id}`);
}

// export function getByPersona(reqBody: any) {
//   return http.post(
//     `${API_URL.INTEGRATION_API_URL}recipe/get-by-persona?persona=${reqBody?.persona}&country=${reqBody?.country}&veg=${reqBody?.veg}&sort=${reqBody?.sort}`, reqBody
//   );
// }

export function getByPersona(reqBody: {
  persona: string;
  country: string;
  veg: boolean;
  sort?: string;
  cuisines?: string[];
}) {
  let nevUrl = `recipe/get-by-persona?persona=${reqBody.persona}&country=${reqBody.country}&veg=${reqBody.veg}`;
  if (reqBody.sort && reqBody.sort !== "") {
    nevUrl += `&sort=${reqBody.sort}`;
  }
  if (reqBody.cuisines && reqBody.cuisines.length > 0) {
    nevUrl += `&cuisines=[${reqBody.cuisines.join(",")}]`;
  }
  console.log("Constructed URL:", `${API_URL.INTEGRATION_API_URL}${nevUrl}`);
  return http.post(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function getCategoryByCouisine(id: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}order/user/${id}`);
}

export function getProducts(country: string, reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}shop/products?country=${country}`,
    reqBody
  );
}

export function getSingleProduct(id: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}shop/${id}`);
}

export function getSubscriptionInfo(id: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}user/sub-info/${id}`);
}

export function subScribeBasic(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}user/subscribe-basic`,
    reqBody
  );
}

export function createPlan(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-subscription-order`,
    reqBody
  );
}

export function createPlanRozarpay(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-subscription-razorpay`,
    reqBody
  );
}

export function cancelByStripe(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}pay/cancel-stripe`, reqBody);
}

export function cancelByRozarpay(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/cancel-razorpay`,
    reqBody
  );
}

export function cancelByCOD(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}pay/cancel-cod`, reqBody);
}

export function getAllCoupon(country: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}coupon/all?country=${country}`);
}

export function redeemCoupon(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}coupon/redeem`, reqBody);
}
