import { useState } from "react";

interface FAQItem {
  question: string;
  answer: string;
}

const FAQSection = () => {
  // FAQ data
  const faqData: FAQItem[] = [
    {
      question: "Do you offer next-day delivery?",
      answer:
        "Yes, we do offer next-day delivery. However, availability may vary depending on the PIN code.",
    },
    {
      question: "What kind of recipe kits does Anyfeast offer?",
      answer:
        "We offer a variety of recipe kits, including vegetarian and non-vegetarian options.",
    },
    {
      question: "Are the ingredients in Anyfeast recipe kits fresh?",
      answer:
        "Yes, we ensure that all ingredients in the recipe kits are fresh and of high quality.",
    },
    {
      question: "Can I customize the ingredients in my recipe kit order?",
      answer:
        "Yes, you can customize the ingredients to suit your preferences.",
    },
    {
      question: "Can I cancel part of my order or just specific items?",
      answer:
        "Yes, you can cancel specific items from your order before it's processed.",
    },
  ];

  return (
    <div className="mt-10 md:mx-10 p-4">
      {/* <h2 className="text-3xl font-bold mb-6">Frequently asked questions</h2> */}
      <div className="flex items-center my-8">
        <div className="flex-grow border-t border-gray-300"></div>
        <h1 className="mx-4 text-xl md:text-3xl font-semibold">
          Frequently asked questions
        </h1>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      <div className="space-y-4 pb-8 md:p-16">
        {faqData.map((item, index) => (
          <Accordion key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

const Accordion = ({ item }: { item: FAQItem }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${isOpen ? "" : "border-b rounded-xl"}`}>
      <div className={`px-6 font-sans ${isOpen ? "rounded-xl bg-[#FEF5F5] pt-4" : ""}`}>
        <button
          className={`flex justify-between items-center w-full text-left text-lg font-bold font-sans  ${
            isOpen ? "" : "py-4"
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {item.question}
          <div className="ml-2 text-2xl">{isOpen ? "-" : "+"}</div>
        </button>
        {isOpen && (
          <div className="pb-4 text-gray-600 font-sans">{item.answer}</div>
        )}
      </div>
    </div>
  );
};

export default FAQSection;
