import { useNavigate } from "react-router-dom";
import BlogPost from "./blogPost";
import blog1 from "../../../src/assets/images/blog1.png";

const BlogPage = () => {
  const navigate = useNavigate();
  const handleView = () => {
    navigate("/blogPost");
  };

  return (
    <div className="p-5 md:p-10 lg:p-10 overflow-y">
      <div className="blog text-3xl font-semibold mb-5 md:mb-10 lg:mb-10">Blog</div>
      <div className="shadow-[0_0px_10px_rgba(0,0,0,0.25)] w-full p-5 md:p-10 lg:p-10 rounded-lg text-left">
        <div className="flex flex-row justify-between">
          {/* column */}
          <div className="mr-none lg:mr-10">
            <span className="lg:text-sm md:text-sm text-xs text-[#172B4D]">
              Published on November 14, 2022 | 5 min read
            </span>
            <br />
            <h3 className="text-md md:text-2xl lg:text-2xl text-black font-semibold my-5">
              Luchi and Cholar Dal: A Bengali Navratri Duo with a History of
              Celebrationg
            </h3>
            <div className="flex lg:hidden justify-center w-full h-[180px] md:h-[280px] rounded-lg mb-5">
              <img src={blog1} alt="" className="w-full object-cover rounded-lg" />
            </div>
            <div className="text-sm md:text-lg lg:text-lg">
              In the heart of Bengal, food and festivals go hand in hand, and
              Navratri is no exception. While each region in India has its
              unique way of celebrating the festival, Bengal’s take on Navratri
              is a culinary celebration steeped in history and tradition. One of
              the most beloved pairings during this time is Luchi and Cholar
              Dal. Together, they form a divine duo that’s both rich in flavor
              and cultural significance, making it a must-have during the
              festive season.  Let’s dive into the origins of Luchi and Cholar
              Dal, their significance in Bengali cuisine......
            </div>
            <button
              onClick={() => handleView()}
              className="bg-[#0B6E27] text-white rounded-md font-medium text-[16px] sm:text-[14px] lg:text-[16px] text-[#0B6E27] px-2 mt-2 sm:px-8 lg:px-3 py-2 sm:py-2.5 lg:py-2 mt-8 lg:mt-8"
            >
              Read More
            </button>
          </div>

          {/* image */}
          <div>
            <div className=" hidden lg:flex justify-center w-[411px] h-[411px]">
              <img src={blog1} alt="" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <BlogPost />
    </div>
  );
};

export default BlogPage;
