import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Package from '../../assets/images/Package.png';
import UserList from '../../assets/images/UserList.png';
import UserCircle2 from '../../assets/images/UserCircle2.png';
import SmileyNervous from '../../assets/images/SmileyNervous.png';
import BellRinging from '../../assets/images/BellRinging.png';
import SignOut from '../../assets/images/SignOut.png';

interface SidebarProps {
  onSidebarItemClick: (itemName: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onSidebarItemClick }) => {
  const [activeButton, setActiveButton] = useState<string>("Orders");
  const [highlightStyle, setHighlightStyle] = useState({
    top: 0,
    height: 0
  });
  const buttonRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});
  const navigate = useNavigate();

  const handleClick = (buttonName: string) => {
    setActiveButton(buttonName);
    onSidebarItemClick(buttonName);
    if (buttonName === 'Logout') {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('userName');
      localStorage.removeItem('userPhone');
      navigate('/');
    }
  };

  useEffect(() => {
    const activeButtonEl = buttonRefs.current[activeButton];
    if (activeButtonEl) {
      setHighlightStyle({
        top: activeButtonEl.offsetTop,
        height: activeButtonEl.offsetHeight
      });
    }
  }, [activeButton]);

  const menuItems = [
    { title: 'Orders', subtitle: 'See All your Orders', icon: Package },
    { title: 'Addresses', subtitle: 'Edit Addresses', icon: UserList },
    { title: 'Profile', subtitle: 'Make Changes To Your Account', icon: UserCircle2 },
    { title: 'Allergies & Dislikes', subtitle: 'Help us with your Allergies & Dislikes', icon: SmileyNervous },
    { title: 'About Us', subtitle: 'Know More About Us', icon: BellRinging },
    { title: 'Logout', subtitle: 'Further secure your account for safety', icon: SignOut }
  ];

  return (
    <div className="bg-white w-96 relative">
      {/* Sliding red line */}
      <div 
        className="absolute left-0 w-[3px] bg-[#C30F16] transition-all duration-1000 ease-in-out"
        style={{
          top: highlightStyle.top,
          height: highlightStyle.height
        }}
      />
      
      {/* Sliding background highlight */}
      <div 
        className="absolute left-[3px] right-0 bg-[rgb(255,243,243)] transition-all duration-1000 ease-in-out"
        style={{
          top: highlightStyle.top,
          height: highlightStyle.height
        }}
      />

      {/* Menu items */}
      {menuItems.map(({ title, subtitle, icon }) => (
        <button
          key={title}
          ref={el => buttonRefs.current[title] = el}
          onClick={() => handleClick(title)}
          className="block relative w-full text-left py-4"
        >
          <div className={`flex items-center ${activeButton === title ? 'text-[#C30F16] ml-3' : 'ml-4'}`}>
            <div className="rounded-full w-10 h-10 mr-4 mt-1 bg-[#FFE2E2] flex items-center justify-center">
              <img src={icon} alt={title} className="w-6 h-6" />
            </div>
            <div>
              <h2 className="text-sm mb-0.5 font-semibold">{title}</h2>
              <p className={`text-xs mb-1 ${activeButton === title ? 'text-[#C30F16]' : 'text-gray-500'}`}>
                {subtitle}
              </p>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default Sidebar;