import React from "react";
import PauseOnHover from './PauseOnHover'; // Import the slider component
import Italian from "../../assets/images/Italian.png"; // Cuisine images
import French from "../../assets/images/French.png";
import Chinese from "../../assets/images/Chinese.png";
import Japanese from "../../assets/images/Japanese.png";
import Mexican from "../../assets/images/Mexican.png";
import American from "../../assets/images/American.png";
import Korean from "../../assets/images/Korean.png";
import ExoticVegetable1 from "../../assets/images/ExoticVegetable1.png"; // Exotic vegetable images
import ExoticVegetable2 from "../../assets/images/ExoticVegetable2.png";
import ExoticVegetable3 from "../../assets/images/ExoticVegetable3.png";
import ExoticVegetable4 from "../../assets/images/ExoticVegetable4.png";
import ExoticVegetable5 from "../../assets/images/ExoticVegetable5.png";
import CaretCircleRight from "../../assets/images/CaretCircleRight.png";
import ArrowDown2 from '../../assets/images/Arrow - Down 2.png';
// import ProductItems from "./ProductItems"
import ProductTrial from "./ProductTrial";
import { Link, useNavigate } from "react-router-dom";
import DelightReceipe from "../Home/DelightReceipe";
import ProductItems from "./ProductItems";
import DelightTrial from "./RecipeKitTrial";
import CuisineSlider from "../Home/SwiperSlider";
import CuisineSliderTrial from "./CuisineSliderTrial";

const ShopScreens: React.FC = () => {
  const navigate = useNavigate();
  const handleView = (cuisine: string) => {
    navigate(`/shop/cuisine/${cuisine}`);
  };
  return (
    <div className="min-h-screen bg-[#FFFFFF] font-dm-sans">


      {/* Featured Brands Section */}
      <div className="flex flex-col items-start my-5">
        {/* Title Section */}
        <div className="w-full px-4 md:px-8 lg:px-16"> {/* Adjusted padding for responsiveness */}
          <div className="text-left"> {/* Ensures left alignment */}
            <p className="font-semibold mt-2 text-lg md:text-xl lg:text-2xl text-[#666666]"> {/* Responsive text size */}
              Featured Brands
            </p>
            <div>
              <hr className="border-red-600 my-2 border-2 rounded-lg w-2/6  md:w-1/6 lg:w-1/8" /> {/* Line width adjustment for different screen sizes */}
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="flex justify-between w-full px-4 md:px-8 lg:px-10 space-x-2 md:space-x-4"> {/* Adjusted padding and spacing */}
          <PauseOnHover />
        </div>
      </div>

      <div className="h-6"></div>

      <CuisineSliderTrial />
      <div className="h-10"></div>
      <ProductTrial title="Exotic Vegetables" />
      <div className="h-10"></div>
      <ProductTrial title="Snacks" />
      <div className="h-10"></div>
      <ProductTrial title="Fruits" />
      <div className="h-4"></div>
      <DelightTrial />
    </div>
  );
};

export default ShopScreens;



//exotic vegetable
{/* Exotic Vegetables Section */ }
//  <div className="flex flex-col items-center my-8 font-dm-sans">
//  <div className="flex justify-between w-full px-16">
//    <div>
//      <p className="  text-[24px] text-[#212121] mt-2">
//        Exotic Vegetables
//      </p>
//    </div>
//    <div className="flex flex-col items-start ">
//      <div className="flex items-center ">
//        <span className="text-[#C30F16] text-[18px] font-medium mr-3">
//          View All
//        </span>
//        <img src={CaretCircleRight} className="h-6" alt="Caret Icon" />
//      </div>
//      <div className="w-full h-[2px] bg-[#C30F16] mt-1" />
//    </div>
//  </div>
// // <div className="flex justify-between w-full mt-4 px-16 space-x-4">
// //</div>          {/* Exotic Vegetable Cards */}
// //           {[
// //             {
//               image: ExoticVegetable1,
//               name: "B Natural Mixed Fruit Juice",
//               price: "₹100",
//               discountPrice: "₹110",
//             },
//             {
//               image: ExoticVegetable2,
//               name: "Hen Fruit White Protein Eggs",
//               price: "₹140",
//               discountPrice: "₹160",
//             },
//             {
//               image: ExoticVegetable3,
//               name: "Go Cheese Slices",
//               price: "₹140",
//               discountPrice: "₹160",
//             },
//             {
//               image: ExoticVegetable4,
//               name: "Soan Papdi by Charlie's",
//               price: "₹51",
//               discountPrice: "₹96",
//             },
//             {
//               image: ExoticVegetable5,
//               name: "Cremica Chocolate Thicker Syrup",
//               price: "₹194",
//               discountPrice: "₹225",
//             },
//           ].map((veg, index) => (
//             // <div key={index} className="bg-[#F3F9FB] p-4 rounded-lg flex flex-col items-center shadow-lg w-[240px]">
//             //   <img src={veg.image} alt={veg.name} className="w-full h-[150px] object-contain" />
//             //   <p className="text-center mt-2 font-medium text-[20px]">{veg.name}</p>
//             //   <p className="mt-1 text-center text-[#FF0000] text-[20px]">{veg.price}</p>
//             //   <p className="text-gray-500 line-through text-[16px]">{veg.discountPrice}</p>
//             //   <button className="bg-[#C30F16] hover:bg-red-600 text-white py-2 px-4 mt-4 rounded-lg">
//             //     Add
//             //   </button>
//             // </div>
//             <div>
//               {/* cardss pg */}
//               <div className="flex space-x-10">
//                 <div className="rounded-2xl border border-[#EDEDED] shadow-none relative overflow-hidden">
//                   <Link to="/product/1">
//                     <div className="bg-[#F3F9FB] px-6 flex flex-col items-center">
//                       <img
//                         src={veg.image}
//                         alt={veg.name}
//                         className="w-[190px] object-cover"
//                       />
//                     </div>
//                   </Link>
//                   <div className="border-t border-[#EDEDED]" />
//                   <div className="bg-white p-3">
//                     <span className="text-[15px] text-[#222222]">
//                       {veg.name}
//                     </span>
//                     <br />
//                     <span className="text-[15px]">
//                       {/* {veg.description} */}
//                     </span>
//                     <div className="border-t border-[#EDEDED] my-2" />
//                     <div className="flex justify-between">
//                       <div>
//                         <span className="text-[18px] font-medium text-[#3BB77E] mr-2">
//                           ₹{veg.price}
//                         </span>
//                         <span className="text-[18px] font-medium text-[#ADADAD] line-through ml-2">
//                           ₹{veg.discountPrice}
//                         </span>
//                       </div>
//                       <div>
//                         <button className="py-1 px-4 border border-[#0B6E27] rounded-md font-medium text-[14px] text-[#0B6E27] hover:bg-[#0B6E27] hover:text-white">
//                           Add
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//exotic vegetables
{/* 
       <div className='px-16 '>
        <div className="flex justify-between mb-10">
          <span className="text-black text-[24px] font-medium">
            Mega Sale Upto 50% OFF
          </span>
          <a href="#">
          <div className="flex flex-col items-start cursor-pointer">
            <div className="flex items-center">
              <span className="text-[#C30F16] text-[16px] font-medium mr-3">
                View All
              </span>
              <img 
              src={CaretCircleRight}
              className='h-6'
                alt="Caret Icon" 
                />
            </div>
            <div className="w-full h-[2px] bg-[#C30F16] mt-1" />
          </div>
          </a>
        </div>
        <ProductItems/>
        
      </div> */}



//cuisine card
{/* <div className="flex flex-col items-start px-4 sm:px-8 lg:px-16 my-2 font-dm-sans">
  <h2 className="text-[20px] sm:text-[24px] lg:text-[28px] font-semibold">
    Shop Unique Ingredients by Cuisine
  </h2>
  <div className="flex justify-between w-full mt-2">
    <span className="text-black text-[16px] sm:text-[20px] lg:text-[24px] font-medium">Categories</span>
    <a href="/" className="flex flex-col items-start cursor-pointer">
      <div className="flex items-center">
        <span className="text-[#C30F16] text-[14px] sm:text-[16px] lg:text-[18px] font-medium mr-2 sm:mr-3">
          View All
        </span>
        <img src={CaretCircleRight} className="h-4 sm:h-5 lg:h-6" alt="Caret Icon" />
      </div>
      <div className="w-full h-[1px] sm:h-[2px] bg-[#C30F16] mt-1" />
    </a>
  </div>

  {/* Cuisine Cards Section */}
//   <div className="flex mt-4 overflow-x-scroll scrollbar-hide space-x-4 sm:space-x-2 lg:space-x-3 w-full">
//     {[
//       { image: Italian, name: "Italian" },
//       { image: French, name: "French" },
//       { image: Chinese, name: "Chinese" },
//       { image: Japanese, name: "Japanese" },
//       { image: Mexican, name: "Mexican" },
//       { image: American, name: "American" },
//       { image: Korean, name: "Korean" },
//     ].map((cuisine, index) => (
//       <div
//         key={index}
//         className="flex flex-col items-center cursor-pointer min-w-[120px] sm:min-w-[150px] lg:min-w-[180px]"
//         onClick={() => handleView(cuisine.name)}
//       >
//         <img
//           src={cuisine.image}
//           alt={`${cuisine.name} Cuisine`}
//           className="w-[100px] sm:w-[120px] lg:w-[150px] h-auto object-cover rounded-lg"
//         />
//         <p className="mt-2 text-center text-[14px] sm:text-[18px] lg:text-[22px] text-[#424242]">
//           {cuisine.name}
//         </p>
//       </div>
//     ))}
//   </div>
// </div> */}