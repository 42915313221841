import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CaretCircleRight from '../../assets/images/right.png'; // View All arrow icon

const shopCuisines = [
  "italian", "french", "chinese", "japanese", "mexican", "american", 
  "korean", "thai", "indian", "spanish", "british", "brazilian", 
  "canadian", "greek", "general"
];

const categories = shopCuisines.map(cuisine => ({ name: cuisine.charAt(0).toUpperCase() + cuisine.slice(1) }));

const CuisineSliderTrial: React.FC = () => {
  const navigate = useNavigate();
  const [selectedRecipe, setSelectedRecipe] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 7;

  const handleCategoryClick = (name: string) => {
    setSelectedRecipe(name);
    navigate(`/shop/cuisine/${name.toLowerCase()}`);
  };

  const handleLeftClick = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleRightClick = () => {
    setStartIndex((prevIndex) => Math.min(prevIndex + 1, categories.length - itemsToShow));
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: itemsToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 640,
        settings: { slidesToShow: 3, slidesToScroll: 1, itemWidth: 100 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 7, slidesToScroll: 1, itemWidth: 120 },
      },
    ],
  };

  return (
    <div className="px-4 sm:px-8 lg:px-16 my-4 font-dm-sans">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-[20px] sm:text-[24px] lg:text-[28px] font-bold sm:font-bold">
          Shop Unique Ingredients by Cuisine
        </h2>
        <a href="/">
          <div className="flex flex-col items-start cursor-pointer">
            <div className="flex items-center">
              <span className="text-[#C30F16] text-[14px] sm:text-[18px] font-medium mr-1 sm:mr-3">
                View All
              </span>
              <img src={CaretCircleRight} className="h-5 sm:h-6" alt="Caret Icon" />
            </div>
            <div className="w-full h-[2px] bg-[#C30F16] mt-1" />
          </div>
        </a>
      </div>

      <Slider {...settings} >
        {categories.slice(startIndex, startIndex + itemsToShow).map((category) => (
          <div
            key={category.name}
            className={`flex flex-col items-center text-center cursor-pointer ${selectedRecipe === category.name ? 'text-[#C30F16]' : ''}`}
            onClick={() => handleCategoryClick(category.name)}
          >
            <img 
              src={`https://anyfeast.blob.core.windows.net/festival/${category.name.toLowerCase()}.png`} 
              alt={category.name} 
              className="object-cover w-[100px] sm:w-[120px] lg:w-[150px] h-auto mb-2" 
            />
            <button
              className={`text-center text-[14px] sm:text-[16px] lg:text-[18px] ${selectedRecipe === category.name ? 'text-[#C30F16]' : ''}`}
            >
              {category.name}
            </button>
            {selectedRecipe === category.name && <div className="h-1 rounded bg-[#C30F16] w-3/4 mt-1" />}
          </div>
        ))}
      </Slider>

      <div className="flex items-center justify-center gap-4 pt-4">
        <button
          className="bg-white shadow-md p-2 rounded-full"
          onClick={handleLeftClick}
          disabled={startIndex === 0}
        >
          &larr;
        </button> 
        <button
          className="bg-white shadow-md p-2 rounded-full"
          onClick={handleRightClick}
          disabled={startIndex + itemsToShow >= categories.length}
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default CuisineSliderTrial;
