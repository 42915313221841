import { ToastOptions } from "react-toastify"
export const Constants = {
    // accessToken: localStorage.getItem("accessToken"),
    loadStripe: "pk_test_51O2gHWLbzaFGUNKkFPaklXqHR0y0u6ITzr1kdnIB8aDAQtUFvbbRvtVrAu1CjkZp4mMEIVZMcdPtNagTMpkdREJ6008KBJHX1w",
    clientRoot: "https://localhost:3000",
}

export const API_URL = {
    INTEGRATION_API_URL: process.env.REACT_APP_API_URL,
}
export const TOASTIFY_CONF: ToastOptions<{}> = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    rtl: false,
}