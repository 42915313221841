import '../../App.css';
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../hooks';
import { addAddressAsync } from '../../services/reducers/reciepeReducer';
import { Country, State, City } from 'country-state-city';
import Select from 'react-select'; // Import react-select

interface AddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  getAddress: any;
}

interface OptionType {
  label: string;
  value: string;
}

const AddressModal: React.FC<AddressModalProps> = ({ isOpen, onClose, getAddress }) => {
  const [selectedCountry, setSelectedCountry] = useState<OptionType | null>(null);
  const [selectedState, setSelectedState] = useState<OptionType | null>(null);
  const [cityOptions, setCityOptions] = useState<OptionType[]>([]);
  const [customCity, setCustomCity] = useState('');
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");
  const [customAddressType, setCustomAddressType] = useState('');


  const validationSchema = Yup.object().shape({
    street_address: Yup.string().required('Street address is required'),
    //city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    postal_code: Yup.string().required('Postal code is required'),
    country: Yup.string().required('Country is required'),
    address_type: Yup.string().required('Address type is required'),
  });

  const handleSubmit = async (values: any) => {
    const request = {
      user_id: userId,
      street_address: values.street_address,
      city: selectedCity(),
      state: selectedState?.label || '',
      postal_code: values.postal_code,
      country: selectedCountry?.label || '',
      flat_no: "",
      address_type: customAddressType || values.address_type,
    };

    dispatch(addAddressAsync(request)).then((res) => {
      getAddress();
      onClose();
    });
  };

  const countryOptions = Country.getAllCountries().map((country: any) => ({
    value: country.isoCode,
    label: country.name,
  }));

  const stateOptions = selectedCountry
    ? State.getStatesOfCountry(selectedCountry.value).map((state: any) => ({
      value: state.isoCode,
      label: state.name,
    }))
    : [];

  useEffect(() => {
    if (selectedCountry) {

    citySelect();
    }
  }, [selectedState, selectedCountry]);

  if (!isOpen) { return null; }

  const citySelect = () => {
    if (selectedCountry) {
      if (selectedCountry.value === 'GB' && selectedState) {
        const cities = City.getCitiesOfState(selectedCountry.value, selectedState.value).map((city: any) => ({
          label: city.name,
          value: city.name,
        }));
        setCityOptions(cities);
      } else if (selectedState) {
        const cities = City.getCitiesOfState(selectedCountry.value, selectedState.value).map((city: any) => ({
          label: city.name,
          value: city.name,
        }));
        setCityOptions(cities);
      } else {
        setCityOptions([]);
      }
    } else {
      setCityOptions([]); 
    }
  };

  const selectedCity = () => {
    return customCity || (cityOptions.length > 0 ? cityOptions[0].value : '');
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-md sm:max-w-lg lg:max-w-3xl mx-4 relative overflow-y-auto max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute top-0 right-2 text-gray-500 text-xl font-bold"
        >
          &times;
        </button>

        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-800">Add New Address</h2>
        </div>

        <Formik
          initialValues={{
            street_address: '',
            postal_code: '',
            address_type: '',
            country: '',
            state: '',
            city: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Username</label>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <Field
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                  <Field
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Address</label>
                <Field
                  type="text"
                  name="street_address"
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                />
                {errors.street_address && touched.street_address ? (
                  <div className="text-red-600 text-sm">{errors.street_address}</div>
                ) : null}
              </div>

              {/* Country Dropdown with Search */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Country</label>
                  <Select
                    options={countryOptions}
                    value={selectedCountry}
                    onChange={(option) => {
                      setSelectedCountry(option);
                      setSelectedState(null); 
                      setCityOptions([]); 
                      setCustomCity(''); 
                      setFieldValue('country', option?.label);
                    }}
                    placeholder="Select Country"
                  />
                  {errors.country && touched.country ? (
                    <div className="text-red-600 text-sm">{errors.country}</div>
                  ) : null}
                </div>

                {/* State Dropdown with Search */}
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Region/State</label>
                  <Select
                    options={stateOptions}
                    value={selectedState}
                    onChange={(option) => {
                      setSelectedState(option);
                      setFieldValue('state', option?.label);
                      setCityOptions([]); 
                    }}
                    placeholder="Select State"
                    isDisabled={!selectedCountry}
                  />
                  {errors.state && touched.state ? (
                    <div className="text-red-600 text-sm">{errors.state}</div>
                  ) : null}
                </div>

                {/* City Dropdown with Search */}
                {(selectedState || cityOptions.length === 0) && (
                  <div>
                    <label className="block text-sm text-gray-600 mb-1 font-bold">Custom City</label>
                    <input
                      type="text"
                      value={customCity}
                      onChange={(e) => setCustomCity(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                      placeholder="Enter your city"
                    />
                    {errors.city && touched.city ? (
                      <div className="text-red-600 text-sm">{errors.city}</div>
                    ) : null}
                  </div>
                )}

                {/* Postal Code Field */}
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Zip Code</label>
                  <Field
                    type="text"
                    name="postal_code"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                  {errors.postal_code && touched.postal_code ? (
                    <div className="text-red-600 text-sm">{errors.postal_code}</div>
                  ) : null}
                </div>
              </div>

              {/* Address Type Dropdown */}
              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Address Type</label>
                <Field
                  as="select"
                  name="address_type"
                  onChange={(e:any) => {
                    setCustomAddressType(e.target.value);
                    setFieldValue('address_type', e.target.value);
                  }}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                >
                  <option value="" label="Select address type" />
                  <option value="Home" label="Home" />
                  <option value="Office" label="Office" />
                </Field>
                {errors.address_type && touched.address_type ? (
                  <div className="text-red-600 text-sm">{errors.address_type}</div>
                ) : null}
              </div>

              <button
                type="submit"
                className="w-full bg-[#0B6E27] text-white py-2 rounded-md hover:bg-[#0A5E21] transition duration-200"
              >
                Save Address
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddressModal;
