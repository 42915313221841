import React, { useState, useRef, useEffect } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import Pagination from './pagination';
import { useAppDispatch } from '../../hooks';
import { addUpdateItemAsync, getCatCousineAsync, getItemsByIdAsync, getProductsAsync, getSubCatByCousineCatAsync } from '../../services/reducers/reciepeReducer';
import { useNavigate, useParams } from 'react-router-dom';

const IngredientShop: React.FC = () => {
	const cuisines = ["Italian", "French", "Chinese", "Japanese", "Mexican", "American", "Korean", "Thai", "Indian", "Spanish", "British", "Brazilian", "Canadian", "Greek", "General"];
	const { cuisine } = useParams();
	const dispatch = useAppDispatch();
	const [categoriesDetail, setCategoriesDetail] = useState([]);
	const [selectedCuisine, setSelectedCuisine] = useState(
		cuisine ? cuisine.toLowerCase() : cuisines.map(c => c.toLowerCase())
	);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [selectedSubcategory, setSelectedSubcategory] = useState();
	const [products, setProducts] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
	const navigate = useNavigate();
	const [isSmallScreen, setIsSmallScreen] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const totalPages = 32;
	const cuisineSliderRef = useRef<HTMLDivElement>(null);
	const categorySliderRef = useRef<HTMLDivElement>(null);
	const productGridRef = useRef<HTMLDivElement>(null);
	const userId = localStorage.getItem("userId");

	useEffect(() => {
		const updateScreenSize = () => {
			setIsSmallScreen(window.innerWidth < 768);
		};
		updateScreenSize();
		window.addEventListener('resize', updateScreenSize);
		return () => window.removeEventListener('resize', updateScreenSize);
	}, []);


	const handlePageChange = (page: number) => {
		if (page >= 1 && page <= totalPages) {
			setCurrentPage(page);
		}
	};

	const scroll = (ref: React.RefObject<HTMLDivElement>, direction: 'left' | 'right') => {
		if (ref.current) {
			const scrollAmount = 200;
			const newScrollLeft = direction === 'left'
				? ref.current.scrollLeft - scrollAmount
				: ref.current.scrollLeft + scrollAmount;
			ref.current.scrollTo({
				left: newScrollLeft,
				behavior: 'smooth'
			});
		}
	};
	const [selectedCountry, setSelectedCountry] = useState('');
	const [countryCode, setCountryCode] = useState<string | null>(null);
  
	useEffect(() => {
	  const storedCountry = localStorage.getItem('selectedCountry');
	  const storedCode = localStorage.getItem('countryCode');
	  if (storedCountry) {
		setSelectedCountry(storedCountry);
		setCountryCode(storedCode);
	  }
	}, []);
  
	useEffect(() => {
	  if (selectedCountry) {
		getCategory();
		getSubCategory();
		getProducts();
	  }
	}, [selectedCountry]);

	useEffect(() => {
		getCategory();
	}, [selectedCuisine]);

	const getCategory = () => {
		const country = "IN";
		if (selectedCuisine) {
			dispatch(getCatCousineAsync({ cuisine: selectedCuisine, country })).then((res) => {
				if (res.payload) {
					setCategoriesDetail(res.payload.data);
				}
			});
		}
	};

	useEffect(() => {
		getSubCategory();
	}, [selectedCategory]);

	const getSubCategory = () => {
		const country = countryCode;

		dispatch(getSubCatByCousineCatAsync({ cuisine: selectedCuisine, country, category: selectedCategory })).then((res) => {
			if (res.payload) {
				setSubCategory(res.payload.data);
			}
		});

	};
	useEffect(() => {
		// if(selectedSubcategory && selectedCuisine && selectedCategory){
		getProducts();
		// }
	}, [selectedSubcategory]);

	const getProducts = () => {
		const country = countryCode ?? '';
		if (selectedSubcategory !== "undefined") {
			const request = {
				sub: selectedSubcategory,
				cuisine: selectedCuisine,
				category: selectedCategory
			};
			dispatch(getProductsAsync({ country: country, request })).then((res) => {
				if (res.payload) {
					setProducts(res.payload.data);
				}
			});
		}
	};

	const handleCategoryChange = (category: any) => {
		setSelectedCategory(category);
		setSelectedSubcategory(undefined);
	};
	const handleView = (id: string) => {
		navigate(`/product/${id}`);
	};

	const handleAdd = (productId: number, ingredient: any) => {
		if (userId) {
			//const newQuantity = (prev[productId] || 0) + 1;
			const request = {
				group: `Shop- -1`,
				country: countryCode,
				items: [
					{
						...ingredient,
						selected: 1,
					},
				],
			};

			dispatch(addUpdateItemAsync({ id: userId, request }))
			.then(() => {
			  dispatch(getItemsByIdAsync({ id: userId, country: countryCode ??'' }));
			});
		}

	};

	return (
		<div>
			<div className={`flex flex-col ${isSmallScreen ? 'h-screen' : ''} w-screen mt-0 bg-[#F9F9F9] overflow-hidden`}>

				<div className={`flex-grow p-4 sm:p-6 lg:p-8 ${isSmallScreen ? 'overflow-hidden' : 'overflow-auto'}`}>
					<div className="mb-4">
						<h2 className="text-xl sm:text-2xl font-semibold text-black">Shop Unique Ingredients</h2>
					</div>

					<div className="flex justify-between mb-4 items-center">
						<h2 className="text-lg sm:text-xl font-medium text-black">Categories</h2>
						<div className="flex items-center text-red-500 cursor-pointer">
							<span className="mr-1 underline decoration-red-500" style={{ textUnderlineOffset: '3px' }}>
								View All
							</span>
							<ChevronRight size={20} />
						</div>
					</div>

					{/* Cuisines section */}
					<div className={`mb-4 relative bg-white rounded-lg ${isSmallScreen ? 'py-1' : 'py-3'} ${isSmallScreen ? 'w-full' : ''} ${isSmallScreen ? 'sticky top-0 z-10' : ''}`}> {/* Reduced py-2 for small screens */}
						<div
							ref={cuisineSliderRef}
							className={`flex overflow-x-auto pb-2 px-4 ${isSmallScreen ? 'space-x-1' : 'space-x-2'} bg-white scrollbar-hide`} >
							{cuisines.map((cuisineItem) => (
								<button
									key={cuisineItem}
									className={`px-3 py-1 ${isSmallScreen ? 'text-xs' : 'text-sm'} mx-1 rounded-full whitespace-nowrap transition-all ${selectedCuisine === cuisineItem.toLowerCase()
										? 'bg-[#FFC4C4] text-[#C30F16] shadow-[#FFC4C4] shadow-md'
										: 'bg-white text-[#313131]'
										}`}
									onClick={() => setSelectedCuisine(cuisineItem.toLowerCase())}
								>
									{cuisineItem}
								</button>
							))}
						</div>
						{!isSmallScreen && (
							<>
								<button
									onClick={() => scroll(cuisineSliderRef, 'left')}
									className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-1 shadow-md"
								>
									<ChevronLeft size={20} />
								</button>
								<button
									onClick={() => scroll(cuisineSliderRef, 'right')}
									className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-1 shadow-md"
								>
									<ChevronRight size={20} />
								</button>
							</>
						)}
					</div>


					{/* Categories section */}

					<div className={`mb-4 relative bg-white rounded-lg ${isSmallScreen ? 'py-1' : 'py-1 md:py-2'} ${isSmallScreen ? 'w-full sticky top-20 z-10' : ''}`}>
						<div
							ref={categorySliderRef}
							className="flex overflow-x-auto px-2 scrollbar-hide"
						>
							<div className={`flex ${isSmallScreen ? 'space-x-1 p-2' : 'space-x-4 p-4'} bg-white rounded-lg ${isSmallScreen ? 'h-24' : 'h-30 md:h-34'}`}> {/* Reduced height for larger screens */}
								{categoriesDetail && categoriesDetail.map((category: any) => (
									<button
										key={category.category}
										className={`flex flex-col items-center ${isSmallScreen ? 'p-1' : 'p-2'} rounded-lg transition-all ${selectedCategory === category.name
											? 'border-2 border-[#DF6A42] shadow-[[#C30F16]] shadow-md'
											: 'border-2 border-transparent hover:border-[#DF6A42]'
											}`}
										// onClick={() => setSelectedCategory(category.category)}
										onClick={() => handleCategoryChange(category.category)}
									>
										<div className={`${isSmallScreen ? 'w-10 h-10' : 'w-20 h-20'} bg-gray-200 rounded-lg mb-2 flex items-center justify-center overflow-hidden`}> {/* Adjusted size for small screens */}
											<img src={category.image} alt={category.category} className="w-full h-full object-cover" />
										</div>
										<span className="text-xs text-center">{category.category}</span>
									</button>
								))}
							</div>
						</div>
						{!isSmallScreen && (
							<>
								<button
									onClick={() => scroll(categorySliderRef, 'left')}
									className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-1 shadow-md"
								>
									<ChevronLeft size={20} />
								</button>
								<button
									onClick={() => scroll(categorySliderRef, 'right')}
									className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-1 shadow-md"
								>
									<ChevronRight size={20} />
								</button>
							</>
						)}
					</div>

					{/* Main content area */}

					<div className={`flex flex-row gap-3 ${isSmallScreen ? '' : 'overflow-y-auto'}`} style={{ height: isSmallScreen ? 'calc(100vh - 250px)' : 'auto' }}>

						{/* Subcategories sidebar */}
						<div className={`${isSmallScreen ? 'w-1/6' : 'w-46'} ${isSmallScreen ? 'sticky top-44 self-start' : ''}`}>
							<div
								className="bg-white py-3 p-2 rounded-lg shadow-md overflow-y-auto"
							// style={{
							//   height: isSmallScreen ? '650px' : productGridRef.current ? `${productGridRef.current.clientHeight}px` : 'auto',
							//   maxHeight: isSmallScreen ? '400px' : 'calc(100vh - 250px)'
							// }}
							>
								<div className={`flex flex-col gap-y-5 space-y-2 rounded-lg ${isSmallScreen ? 'bg-white' : 'bg-white mx-auto'}`}>
									{subCategory.map((subCategory: any) => (
										<button
											key={subCategory.sub_category} // Use sub_category for unique keys
											className={`flex flex-col items-center transition-all rounded-lg text-sm ${selectedSubcategory === subCategory.sub_category
												? 'border-2 border-[#DF6A42] shadow-[[#C30F16]] shadow-md'
												: 'border-2 border-transparent hover:border-[#DF6A42]'
												}`}
											style={{
												margin: '0',
												padding: '2px',
												borderRadius: '2px',
											}}
											onClick={() => setSelectedSubcategory(subCategory.sub_category)}
										>
											<div className={`${isSmallScreen ? 'w-18 h-18' : 'w-24 h-24 p-2'} bg-gray-200 rounded-lg mb-2 overflow-hidden`}>
												<img src={subCategory.image} alt={subCategory.sub_category} className="w-full h-full object-contain" />
											</div>
											<span className="text-xs text-center">{subCategory.sub_category}</span> {/* Display the sub_category name */}
										</button>
									))}
								</div>
							</div>
						</div>

						{/* Products grid */}
						<div ref={productGridRef} className={`${isSmallScreen ? 'w-5/6' : 'w-4/5 lg:w-5/6'}`}>
							<div className={`grid ${isSmallScreen ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-5'} gap-4 rounded-lg bg-white px-2 py-2 overflow-y-auto`} style={{ height: isSmallScreen ? 'calc(100vh - 250px)' : 'auto', maxHeight: isSmallScreen ? 'calc(100vh - 250px)' : 'none' }}>
								{/* {products.length > 0 ? ( */}
								{products.map((product: any, index: number) => (
									<div key={index} className={`md:max-h-[275px] md:max-w-[240px] relative bg-white rounded-lg border cursor-pointer transition-all ${selectedProduct === product.name ? 'border-2 border-red-500 shadow-md' : 'border-2 border-gray-300 hover:border-red-500'}`} onClick={() => { handleView(product.id); setSelectedProduct(product.name); }}>
										{/* {product.discount && (
            <div className={`absolute top-0 right-0 bg-[#54B22C] py-1 px-3 rounded-tr-l rounded-bl-2xl flex items-center justify-center ${isSmallScreen ? 'text-xs' : 'text-sm'}`}>
              <span className="font-semibold text-center text-[#FFFFFF]">{product.discount}% <br /> OFF</span>
            </div>
          )} */}                      <div className="w-full md:h-[150px] bg-[#F3F9FB] rounded-lg mb-3 overflow-hidden">
											<img src={product.image} alt={product.name} className="w-full h-full object-contain" />
										</div>
										<div>
											<h4 className="font-medium text-sm mb-1 mx-3 text-[#000000] line-clamp-1">{product.name}</h4>
											{product.weight && <p className="text-xs text-[#222222] mx-3 mb-1">{product.weight}</p>}
											{/* {product.free && <p className="text-sm mx-3 text-[#54B22C] mb-1">{product.free}</p>} */}
											<div className="border-t border-gray-300 mx-3 mt-3"></div>
											<div className="md:flex items-center justify-between my-2">
												<div>
													<span className="font-bold text-sm ml-3 text-[#3BB77E]">₹{product.price}</span>
													{/* {product.originalPrice && <span className="text-[#606060] line-through text-xs ml-1 mb-2">₹{product.originalPrice}</span>} */}
												</div>
												<div className="flex justify-center">
													<button className="border border-[#0B6E27]  text-[#0B6E27] hover:bg-[#0B6E27] hover:text-white transition mx-9 md:mx-4 py-1 my-2 rounded text-sm w-full max-w-xs md:px-4" onClick={(e) => {
														e.stopPropagation();
														handleAdd(product.id, product);
													}}>Add</button>

												</div>
											</div>


										</div>
									</div>
								))
									// ) : (
									//   <p>No products available for this subcategory.</p>
								}
							</div>
						</div>

					</div>

				</div>
				{/* {!isSmallScreen && (
          <div className="flex justify-center mt-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )} */}
			</div>
		</div>
	);
}

export default IngredientShop;