import React from 'react';
import Icon from '../../../src/assets/images/Icon.png';
import Icon1 from '../../../src/assets/images/Icon1.png';
import Icon2 from '../../../src/assets/images/Icon2.png';
import Icon3 from '../../../src/assets/images/Icon3.png';
import Icon4 from '../../../src/assets/images/Icon4.png';
import Icon5 from '../../../src/assets/images/Icon5.png';
import Icon6 from '../../../src/assets/images/Icon6.png';
import Icon7 from '../../../src/assets/images/Icon7.png';
import Right from '../../../src/assets/images/right.png';

interface Category {
  name: string;
  image: string;
}

const categories: Category[] = [
  { name: 'Fruits', image: Icon },
  { name: 'Vegetables', image: Icon1 },
  { name: 'Dairy', image: Icon2 },
  { name: 'Meat', image: Icon3 },
  { name: 'Bakery', image: Icon4 },
  { name: 'Dry Fruits', image: Icon5 },
  { name: 'Cooking Fats', image: Icon6 },
  { name: 'Coffee', image: Icon7 },
];

const CategoriesSection: React.FC = () => {
  return (
    <div className="lg:p-12 p-5 bg-white lg:mb-12 mb-5 lg:mt-16 mt-5">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-md font-bold mb-2 lg:mb-8 font-sans lg:text-2xl">Shop on Unique Ingredients</h2>
      {/* <button className="text-[#C30F16] border-b-2 border-[#C30F16] font-sans">View All 
        <img src={Right} className='h-6 w-6'/>
      </button> */}
       <button className="text-[#C30F16] border-b-2 border-[#C30F16] font-sans flex items-center flex-row pb-2">
      View All 
      <img src={Right} className="h-6 w-6 ml-2"/>
    </button>
    </div>
    <h3 className="lg:text-xl text-md mb-4 font-sans">Categories</h3>
    <div className="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-8 gap-8">
      {categories.map((category) => (
        <div key={category.name} className="text-center font-sans cursor-pointer">
          <img
            src={category.image}
            alt={category.name}
            className="w-full lg:h-32 h-20 object-cover mb-2 rounded-lg font-sans"
          />
          <p className='font-sans'>{category.name}</p>
        </div>
      ))}
    </div>
  </div>
  );
};

export default CategoriesSection;
