import React, { useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedOption: any;
  setSelectedOption: any;
  getPersonaRecipeDetails:any;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, selectedOption, setSelectedOption, getPersonaRecipeDetails }) => {
  // const [selectedCategory, setSelectedCategory] = useState<
  //   "Cook Time" | "Cost" | "Nutritional Category"
  // >("Cook Time");
  // const [selectedOption, setSelectedOption] = useState<string | null>(null);

  // Define the cost options
  const costOptions = ["High to Low", "Low to High"];
  // const categories = {
  //   "Cook Time": [
  //     "Less than 15 Mins",
  //     "Less than 30 Mins",
  //     "Less than 45 Mins",
  //     "Less than 60 Mins",
  //   ],
  //   Cost: ["High to Low", "Low to High"],
  //   "Nutritional Category": [
  //     "Low on Calories",
  //     "High on Calories",
  //     "Low Carbohydrate",
  //     "High Carbohydrate",
  //     "High Protein",
  //   ],
  // } as const;
  const [selectedCategory, setSelectedCategory] = useState("Cost")
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center oveflow-hidden">
      <div className="bg-white rounded-lg shadow-lg p-6 xl:w-1/3 lg:w-1/3 md:w-1/3 w-3/4">
        {" "}
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold font-sans text-[#313131]">Cost</h2>
          <button
            onClick={onClose}
          //className="text-gray-500 hover:text-gray-700"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 47 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.0948 19.1221L19.2101 28.0067"
                stroke="black"
                stroke-width="3.23077"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.2101 19.1221L28.0948 28.0067"
                stroke="black"
                stroke-width="3.23077"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M30.6975 16.9258L16.9333 30.6899"
                stroke="black"
                stroke-width="5.00513"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.9333 16.9258L30.6975 30.6899"
                stroke="black"
                stroke-width="5.00513"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23.8154 44.4539C35.218 44.4539 44.4616 35.2103 44.4616 23.8078C44.4616 12.4052 35.218 3.16162 23.8154 3.16162C12.4128 3.16162 3.16925 12.4052 3.16925 23.8078C3.16925 35.2103 12.4128 44.4539 23.8154 44.4539Z"
                stroke="black"
                stroke-width="5.00513"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        {/* Body Section */}
        <div className="flex h-[calc(100%-130px)]">

          {/* <div className="w-1/3 border-r h-full">
            <ul className="space-y-4"> */}
              {/* {Object.keys(categories).map((category) => (
                <li
                  key={category}
                  className={`cursor-pointer pl-4 font-sans pt-4 ${
                    selectedCategory === category
                      ? "border-l-4 border-[#C30F16] text-[#C30F16] font-bold rounded"
                      : "text-[#313131]"
                  }`}
                  onClick={() =>
                    setSelectedCategory(
                      category as "Cook Time" | "Cost" | "Nutritional Category"
                    )
                  }
                >
                  {category}
                </li>
              ))} */}

              {/* <li
                key={selectedCategory}
                className={`flex items-center cursor-pointer p-2 ${selectedCategory ? "border-l-4 border-[#C30F16] text-[#C30F16] font-bold rounded"
                    : ""
                  }`}
                onClick={() => setSelectedCategory("Cost")}
              >

                <span className="text-[#313131] font-sans pl-2">{selectedCategory}</span>
              </li>

            </ul>
          </div> */}


          <div className="overflow-y-auto pt-4">
            <ul className="">
              {/* {categories[selectedCategory].map((option) => (
                <li
                  key={option}
                  className={`flex items-center cursor-pointer p-2 `}
                  onClick={() => setSelectedOption(option)}
                >
                  <input
                    type="radio"
                    name="option"
                    className="mr-2"
                    checked={selectedOption === option}
                    onChange={() => setSelectedOption(option)}
                  />
                  <span className={"text-[#313131] font-sans pl-2"}>{option}</span>
                </li>
              ))} */}
              {costOptions.map((option) => (
                <li
                  key={option}
                  className={`flex items-center cursor-pointer p-2 
                  }`}
                  onClick={() => setSelectedOption(option)}
                >
                  <input
                    type="radio"
                    name="cost-option"
                    className="mr-2"
                    checked={selectedOption === option}
                    onChange={() => setSelectedOption(option)}
                  />
                  <span className="text-[#313131] font-sans pl-2">{option}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Footer Section */}
        <div className="flex justify-end">
          <button
            className="px-4 py-2 text-[#313131] mr-2"
            onClick={() => setSelectedOption(null)}
          >
            Clear All
          </button>
          <button
            className="px-7 py-2 bg-[#0B6E27] text-white rounded hover:bg-[#0B6E27] font-sans"
            onClick={() => {
              getPersonaRecipeDetails(); // Call the sorting function here
              onClose(); // Close the modal after applying
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
