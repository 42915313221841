import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addUpdateItemAsync, getItemsByIdAsync } from '../../services/reducers/reciepeReducer';
import '../../App.css';
interface DetailPageProps {
  details?: any;
  id: number;
  quantity: any;
  handleIncrement: (id: number, ingredient: any) => void;
  handleDecrement: (id: number, ingredient: any) => void;
  mainIngrediant: any;
}

const ExoticIngredients: React.FC<DetailPageProps> = ({
  details, id, quantity, handleIncrement, handleDecrement, mainIngrediant
}) => {
  const dispatch = useAppDispatch();
  const servings = useAppSelector((state) => state.servings);
  const userId = localStorage.getItem("userId");
  const [addedItems, setAddedItems] = useState<{ [key: number]: number }>({});

  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryCode, setCountryCode] = useState<string | null>(null);

  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    const storedCode = localStorage.getItem('countryCode');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      setCountryCode(storedCode);
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // getRecipeDetails();
    }
  }, [selectedCountry]);

  useEffect(() => {
    const country = countryCode ?? '';
    if (userId) {
      dispatch(getItemsByIdAsync({ id: userId, country })).then((res) => {
        if (res.payload) {
          const cartItems = res.payload.data.cart?.cart || {};
          const newAddedItems: { [key: number]: number } = {};
          Object.values(cartItems).forEach((items: any) => {
            items.forEach((item: any) => {
              newAddedItems[item.id] = item.selected;
            });
          });

          setAddedItems(newAddedItems);
        }
      });
    }
  }, [dispatch, id]);

  const handleAdd = (ingredient: any) => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }
    const request = {
      group: `${mainIngrediant[0]?.name}-${mainIngrediant[0]?.id}`,
      country: countryCode,
      items: [
        {
          name: ingredient.name,
          id: ingredient.id,
          description: ingredient.description,
          image: ingredient.image,
          price: ingredient.price,
          revised_price: ingredient.revised_price,
          brand: ingredient.brand,
          quantity: ingredient.quantity,
          selected: 1,
        },
      ],
    };
    dispatch(addUpdateItemAsync({ id: userId, request }));
    setAddedItems((prev) => ({ ...prev, [ingredient.id]: 1 }));
  };

  const handleIncrementLocal = (ingredientId: number, ingredient: any) => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }
    setAddedItems((prev) => {
      const newQuantity = (prev[ingredientId] || 0) + 1;
      const request = {
        group: `${mainIngrediant[0]?.name}-${mainIngrediant[0]?.id}`,
        country: countryCode,
        items: [
          {
            ...ingredient,
            selected: newQuantity,
          },
        ],
      };

      dispatch(addUpdateItemAsync({ id: userId, request }));
      return {
        ...prev,
        [ingredientId]: newQuantity,
      };
    });
  };

  const handleDecrementLocal = (ingredientId: number, ingredient: any) => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }
    setAddedItems((prev) => {
      const newQuantity = Math.max(1, (prev[ingredientId] || 0) - 1);
      const request = {
        group: `${mainIngrediant[0]?.name}-${mainIngrediant[0]?.id}`,
        country: countryCode,
        items: [
          {
            ...ingredient,
            selected: newQuantity,
          },
        ],
      };
      dispatch(addUpdateItemAsync({ id: userId, request }));

      return {
        ...prev,
        [ingredientId]: newQuantity,
      };
    });
  };

  return (
    <div className="px-2 lg:p-12 md:p-5 border bg-white shadow-2xl rounded-lg">
    <h2 className="text-xl md:text-2xl lg:text-2xl font-bold mb-6 text-center mt-4 pb-2 font-sans text-[#313131] flex items-center justify-center">
      <span className="border-t-2 border-gray-300 w-16 mx-3"></span>
      Exotic Ingredients
      <span className="border-t-2 border-gray-300 w-16 mx-3"></span>
    </h2>
    {details?.ingredients?.filter((item: any) => item.ingredient_type === 2).map((ingredient: any, index: number) => (
      <ul key={index} className="space-y-6">
        <li className="flex items-center justify-between mb-5">
          {/* Image */}
          <div className="w-16 h-16">
            <img
              src={ingredient.image}
              alt={ingredient.name}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>

          {/* Product details */}
          <div className="flex-1 mx-5 lg:mx-4 md:mx-4 lg:pl-8 md:pl-2">
            <h3 className="text-[18px] lg:text-lg font-semibold text-[#253D4E] pb-2 md:text-[15px] custom-clamp overflow-hidden text-ellipsis">{ingredient.name}</h3>
            {/* <p className="text-gray-500 flex items-center justify-start text-[#454545] pb-2">
              Change Brand
              <span className="pl-8">
                <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 0.5L9 8.5L1 0.5" stroke="#454545" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
            </p> */}
            <p className="text-[#828282] font-sans pb-2 md:text-xs">Pack of {ingredient.quantity}</p>
            <div className="flex items-center space-x-2">
              <span className="text-[#3BB77E] text-lg font-bold pb-2 font-sans">₹{ingredient.price}</span>
              {ingredient.revised_price > 0.00 && (
                <span className="text-[#ADADAD] line-through pb-2 font-sans">₹{ingredient.revised_price}</span>
              )}
            </div>
          </div>

          {/* Quantity selector */}
          {addedItems[ingredient.id] ? (
            <div className="flex items-center bg-[#0B6E27] text-white rounded-lg p-2 font-sans">
              <button
                onClick={() => handleDecrementLocal(ingredient.id, ingredient)}
                className="px-1 md:px-2 lg:px-2 py-1"
                aria-label="Decrease servings"
              >
                -
              </button>
              <span className="px-4 md:px-1">{addedItems[ingredient.id]}</span>
              <button
                onClick={() => handleIncrementLocal(ingredient.id, ingredient)}
                className="px-1 md:px-2 lg:px-2 py-1"
                aria-label="Increase servings"
              >
                +
              </button>
            </div>
          ) : (
            <div className="flex items-center border-2 border-[#0B6E27] text-[#0B6E27] rounded-lg p-2 font-sans">
              <button
                onClick={() => handleAdd(ingredient)}
                className="px-5 lg:px-6 md:px-4 py-1"
              >
                Add
              </button>
            </div>
          )}
        </li>
      </ul>
    ))}

    {details?.ingredients?.filter((item: any) => item.ingredient_type !== 2).length > 0 && (
      <h2 className="pt-5 text-xl md:text-2xl lg:text-2xl font-bold mb-6 text-center pb-2 font-sans text-[#313131] flex items-center justify-center">
        <span className="border-t-2 border-gray-300 w-16 mx-4"></span>
        Staple Ingredients
        <span className="border-t-2 border-gray-300 w-16 mx-4"></span>
      </h2>
    )}
    {details?.ingredients?.filter((item: any) => item.ingredient_type !== 2).map((ingredient: any, index: number) => (
      <ul className="space-y-6" key={index}>

        <li className="flex items-center justify-between mb-5">
          {/* Image */}
          <div className="w-16 h-16">
            <img
              src={ingredient.image}
              alt={ingredient.name}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>

          {/* Product details */}
          <div className="flex-1 mx-5 lg:mx-4 md:mx-4 lg:pl-8 md:pl-2">
            <h3 className="text-[18px] lg:text-lg font-semibold text-[#253D4E] pb-2 md:text-[15px] custom-clamp overflow-hidden text-ellipsis">{ingredient.name}</h3>
            {/* <p className="text-gray-500 flex items-center justify-start text-[#454545] pb-2">Change Brand
              <span className='pl-8'>
                <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 0.5L9 8.5L1 0.5" stroke="#454545" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </p> */}
            <p className="text-[#828282] font-sans pb-2 md:text-xs">Pack of {ingredient.quantity}</p>
            <div className="flex items-center space-x-2">
              <span className="text-[#3BB77E] text-lg font-bold pb-2 font-sans">₹{ingredient.price}</span>
              {ingredient.revised_price > 0.00 && (
                <span className="text-[#ADADAD] line-through pb-2 font-sans">₹{ingredient.revised_price}</span>
              )}
            </div>
          </div>

          {addedItems[ingredient.id] ? (
            <div className="flex items-center bg-[#0B6E27] text-white rounded-lg p-2 font-sans">
              <button
                onClick={() => handleDecrementLocal(ingredient.id, ingredient)}
                className="px-1 md:px-2 lg:px-2 py-1"
                aria-label="Decrease servings"
              >
                -
              </button>
              <span className="px-4 md:px-1">{addedItems[ingredient.id]}</span>
              <button
                onClick={() => handleIncrementLocal(ingredient.id, ingredient)}
                className="px-1 md:px-2 lg:px-2 py-1"
                aria-label="Increase servings"
              >
                +
              </button>
            </div>
          ) : (
            <div className="flex items-center border-2 border-[#0B6E27] text-[#0B6E27] rounded-lg p-2 font-sans">
              <button
                onClick={() => handleAdd(ingredient)}
                className="px-5 lg:px-6 md:px-4 py-1"
              >
                Add
              </button>
            </div>
          )}
        </li>
      </ul>
    ))}
  </div>
  );
};

export default ExoticIngredients;