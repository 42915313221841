import React from "react";
import Sale from '../../../src/assets/images/sale.png';

const SaleSection: React.FC = () => {
  return (
    <div className="bg-white p-5 lg:p-12 grid grid-cols-2 items-center">
      <div className="flex justify-center">
        <img
          src={Sale}
          alt="Meal Kit"
          className="w-full h-36 md:h-auto md:max-w-md object-cover md:object-contain"
        />
      </div>

      <div className="text-center md:text-left">
        <h3 className="uppercase text-sm text-[#313131] mb-2 font-sans font-bold">Summer Sale</h3>
        <h2 className="text-3xl md:text-5xl font-bold text-orange-500 mb-4 font-sans">35%</h2>
        <p className="text-xs md:text-xl text-gray-400 mb-6 font-sans">
          Authentic Meal Kit, Get all Exotic Ingredients for your Authentic Meal
          Kit All in one place
        </p>
        <button className="bg-white text-[#313131] font-sans border border-slate-300 hover:border-[#FF0000] px-6 py-2 md:px-12 md:py-4 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300 border-t-2 font-semibold">
          Explore Recipes
        </button>
      </div>
    </div>
  );
};

export default SaleSection;
