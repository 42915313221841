import React, { useState } from "react";
import ExoticIngredients from "./Exotic";
import SameRecipe from "./SameRecipe";

interface DetailPageProps {
  details?: any;
  ingList: any;
  id: number;
  quantity: any;
  handleIncrement: (id: number, ingredient: any) => void;
  handleDecrement: (id: number, ingredient: any) => void;
  mainIngrediant: any;
}

const RecipePage: React.FC<DetailPageProps> = ({
  details,
  ingList,
  id,
  quantity,
  handleIncrement,
  handleDecrement,
  mainIngrediant,
}) => {
  const [selectedRecipe, setSelectedRecipe] = useState("Recipes");
  const handleCategoryClick = (categoryName: string) => {
    setSelectedRecipe(categoryName);
  };
  const convertToEmbedURL = (url: any) => {
    if (url) {
      const videoId = url.split("v=")[1]?.split("&")[0];
      return `https://www.youtube.com/embed/${videoId}`;
    }
  };

  // Style for hidden scrollbar
  const hiddenScrollbarStyles = "scrollbar-none";

  return (
    <div className="lg:px-12 md:px-6 md:pt-1 lg:pt-1">
      <div className="grid grid-cols-1 md:grid-cols-2 rounded-lg md:gap-5 gap-12">
        <div className="hidden md:block lg:block col-span-1 space-y-6">
          <ExoticIngredients
            details={details}
            id={id}
            mainIngrediant={mainIngrediant}
            quantity={quantity}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
          />
        </div>

        <div className="col-span-1">
          <div className="p-3 lg:p-12 md:p-5  border shadow-xl rounded-lg border-t-2">
            <div className="mb-8 text-center font-sans">
              <button
                className={`md:hidden lg:hidden px-4 pb-2 text-md font-bold ${
                  selectedRecipe === "Order Ingredients"
                    ? "text-[#C30F16] pb-1 border-b-2 border-[#C30F16]"
                    : "text-[#313131]"
                }`}
                onClick={() => handleCategoryClick("Order Ingredients")}
              >
                Order Ingredients
              </button>
              <button
                className={`px-4 pb-2 text-md md:text-xl lg:text-xl font-bold ${
                  selectedRecipe === "Ingredients"
                    ? "text-[#C30F16] pb-1 border-b-2 border-[#C30F16]"
                    : "text-[#313131]"
                }`}
                onClick={() => handleCategoryClick("Ingredients")}
              >
                Ingredients
              </button>
              <button
                className={`px-4 pb-2 text-md md:text-xl lg:text-xl font-bold ${
                  selectedRecipe === "Recipes"
                    ? "text-[#C30F16] pb-1 border-b-2 border-[#C30F16]"
                    : "text-[#313131]"
                }`}
                onClick={() => handleCategoryClick("Recipes")}
              >
                Recipes
              </button>
            </div>

            {/* Recipe Content */}
            <div className="h-[600px] overflow-y-auto scrollbar-none">
              {selectedRecipe === "Recipes" && (
                <div>
                  {details?.recipe?.videos !== null && (
                 <div className="w-full mb-6 "> {/*skkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk*/}
                 <iframe
                   key={details?.recipe?.videos}
                   className="w-full h-[260px] sm:h-[260px] md:h-[260px] lg:h-[260px] rounded-xl"
                   src={convertToEmbedURL(details?.recipe?.videos)}
                   title="Recipe Video"
                   frameBorder="0"
                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                   referrerPolicy="strict-origin-when-cross-origin"
                   allowFullScreen
                 ></iframe>
               </div>
               
                  )}

                  <div className="mb-4 font-sans leading-relaxed">
                    <div className="space-y-4">
                      {details?.recipe?.steps.map(
                        (step: string, index: number) => {
                          const stepParts = step.split(". ");
                          return (
                            <div
                              key={index}
                              className="px-1 flex items-start font-sans leading-relaxed"
                            >
                              <span className="font-bold text-base mr-2 leading-relaxed">
                                {stepParts[0]}.
                              </span>
                              <span className="text-lg">
                                {stepParts.slice(1).join(". ")}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}

              {selectedRecipe === "Ingredients" && (
                <div className="grid gap-4">
                  {details?.ingredients?.map((ingredient: any, index: number) => (
                    <div key={index} 
                         className="flex items-center p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 border border-gray-100">
                      <div className="w-20 h-20 flex-shrink-0">
                        <img
                          src={ingredient.image}
                          alt={ingredient.name}
                          className="w-full h-full object-cover rounded-lg shadow-sm"
                        />
                      </div>
                      <div className="flex-1 ml-6">
                        <h3 className="text-lg font-semibold text-[#253D4E] mb-1 font-sans">
                          {ingredient.name}
                        </h3>
                        <div className="flex items-center gap-4">
                          <span className="text-sm text-[#828282] font-sans bg-gray-50 px-3 py-1 rounded-full">
                            Pack of {ingredient.quantity}
                          </span>
                          <div className="flex items-center gap-2">
                            {ingredient.revised_price > 0 && (
                              <span className="text-sm text-[#ADADAD] line-through font-sans">
                                ₹{ingredient.revised_price}
                              </span>
                            )}
                            <span className="text-sm text-[#3BB77E] font-semibold font-sans">
                              ₹{ingredient.price}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {selectedRecipe === "Order Ingredients" && (
                <div className="md:hidden lg:hidden col-span-1 space-y-6">
                  <ExoticIngredients
                    details={details}
                    id={id}
                    mainIngrediant={mainIngrediant}
                    quantity={quantity}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="p-2 lg:p-3 mt-4 md:p-2 border rounded-lg border-t-2  shadow-2xl hover:shadow-[0px_8px_24px_rgba(0,0,0,0.3)] transform hover:-translate-y-2 transition-all duration-300 ease-in-out">
  <SameRecipe ingList={ingList} />
</div>





        </div>
      </div>
    </div>
  );
};

export default RecipePage;