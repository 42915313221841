import React from "react";
import "../../App.css";
import fruits1 from '../../assets/images/fruits1.png';
import fruits2 from '../../assets/images/fruits2.png';
import fruits3 from '../../assets/images/fruits3.png';
import fruits4 from '../../assets/images/fruits4.png';
import fruits5 from '../../assets/images/fruits5.png';
import CaretCircleRight from "../../assets/images/CaretCircleRight.png";
import { Link } from "react-router-dom";

interface Product {
  id: number;
  name: string;
  description: string;
  price: number;
  originalPrice: number;
  discount: number;
  imageUrl: string;
}

interface ProductTrialProps {
  title: string;
}

const products: Product[] = [
  {
    id: 1,
    name: "B Natural Mixed Fruit Juice",
    description: "(Buy 1 Get 1 FREE)",
    price: 100,
    originalPrice: 110,
    discount: 50,
    imageUrl: fruits1,
  },
  {
    id: 2,
    name: "Hen Fruit White Protein",
    description: "Rich Eggs 10 Pc",
    price: 110,
    originalPrice: 120,
    discount: 40,
    imageUrl: fruits2,
  },
  {
    id: 3,
    name: "Go Cheese Slices",
    description: "200 g",
    price: 140,
    originalPrice: 160,
    discount: 24,
    imageUrl: fruits3,
  },
  {
    id: 4,
    name: "Soan Papdi by Charlie's",
    description: "200 g",
    price: 51,
    originalPrice: 96,
    discount: 41,
    imageUrl: fruits4,
  },
  {
    id: 5,
    name: "Cremica Chocolate Thicker",
    description: "Syrup",
    price: 194,
    originalPrice: 225,
    discount: 20,
    imageUrl: fruits5,
  },
];

const ProductTrial: React.FC<ProductTrialProps> = ({ title }) => {
  return (
    <div className="px-4 sm:px-8 lg:px-16">
      <div className="flex justify-between mb-3 sm:mb-5">
        <span className="text-black text-[18px] sm:text-[24px] font-medium px-2">{title}</span>
        <a href="/">
          <div className="flex flex-col items-start cursor-pointer">
            <div className="flex items-center">
              <span className="text-[#C30F16] text-[14px] sm:text-[18px] font-medium mr-1 sm:mr-3">
                View All
              </span>
              <img src={CaretCircleRight} className="h-5 sm:h-6" alt="Caret Icon" />
            </div>
            <div className="w-full h-[2px] bg-[#C30F16] mt-1" />
          </div>
        </a>
      </div>
      <div>
        <div className="flex space-x-4 sm:space-x-6 lg:space-x-5 overflow-x-scroll scrollbar-hide">
          {products.map((product) => (
            <div
              key={product.id}
              className="min-w-[180px] sm:min-w-[200px] lg:min-w-[200px] flex flex-col rounded-xl border border-[#EDEDED] shadow-none"
            >
              <Link to={`/product/${product.id}`} className="flex-shrink-0">
                <div className="bg-[#F3F9FB] p-3 flex items-center justify-center h-[160px] sm:h-[180px]">
                  <img
                    src={product.imageUrl}
                    alt="Product"
                    className="w-[120px] sm:w-[140px] lg:w-[160px] object-contain"
                  />
                </div>
              </Link>
              
              <div className="border-t border-[#EDEDED]" />
              
              <div className="bg-white p-3 flex flex-col flex-grow">
                <div className="flex-grow">
                  <h3 className="text-[12px] sm:text-[15px] text-[#222222] font-medium mb-1">
                    {product.name}
                  </h3>
                  <p className="text-[12px] sm:text-[14px] text-gray-600 mb-3">
                    {product.description}
                  </p>
                </div>
                
                <div className="border-t border-[#EDEDED] mb-3" />
                
                <div className="flex items-center justify-between mt-auto">
                  <div className="flex items-center">
                    <span className="text-[16px] sm:text-[18px] font-medium text-[#3BB77E]">
                      ₹{product.price}
                    </span>
                    <span className="text-[14px] sm:text-[16px] font-medium text-[#ADADAD] line-through ml-2">
                      ₹{product.originalPrice}
                    </span>
                  </div>
                  <button className="py-1.5 px-4 sm:px-5 border border-[#0B6E27] rounded-md font-medium text-[12px] sm:text-[14px] text-[#0B6E27] hover:bg-[#0B6E27] hover:text-white transition-colors">
                    Add
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductTrial;