import React from 'react';
import story from "../../../src/assets/images/story.png";
import value from "../../../src/assets/images/value.png";
import mission from "../../../src/assets/images/mission.png";
import vision from "../../../src/assets/images/vision.png";

const OurStory: React.FC = () => {
  return (
    <div className="min-h-screen">
      
      {/* Main Story Section */}
      <div className="w-full">
        <div>
          <img 
            src={story} 
            alt="Founder" 
            className="w-full min-h-32 object-cover rounded-none mb-5"
          />
          {/* Text overlay */}
          <div className="relative">
            <div className="absolute -top-20 left-1 md:-top-32 md:left-3 lg:-top-48 lg:left-20 p-1 text-gray-700">
              <p className="text-xs text-white font-normal md:text-2xl">Pankaj Kale</p>
              <p className="text-xs text-white font-light md:text-xl">Founder</p>
              <p className="text-base text-white font-black md:text-4xl lg:text-6xl">Our Story</p>
            </div>
          </div>
        </div>

        {/* Our Story */}
        <div className="text-center px-4 md:px-12 lg:px-24">
          <div className="md:text-[26px] text-gray-700 text-start md:py-4">
            <p className="mb-10 font-normal leading-relaxed tracking-wide">
              <b>Hi, and welcome to AnyFeast! I'm Pankaj Kale </b>a foodie, traveler, and technology enthusiast who’s always had a passion for discovering new flavors and recipes. Growing up in a middle-class family in India, I didn’t frequent fancy restaurants. Instead, I spent my childhood experimenting in the kitchen, blending spices and trying out different dishes.
            </p>
            <p className="mb-10 font-normal leading-relaxed tracking-wide">
              After completing my education in the UK, I traveled the world in search of the best spices and culinary experiences. But with each move, I faced the same challenge—finding authentic, high-quality ingredients without spending hours researching online or navigating new grocery stores. It was frustrating and time-consuming.
            </p>
            <p className="font-normal leading-relaxed tracking-wide">
              That’s why I created AnyFeast a simpler, better way to explore and enjoy new cuisines from the comfort of your home. No more endless searches for quality ingredients. With AnyFeast, I’m bringing the world’s flavours right to your doorstep, making it easy and convenient for you to cook and savor amazing dishes.
            </p>
          </div>
        </div>
      </div>

      {/* Vision and Mission Section */}
      <section className="py-10 px-4 md:px-6 lg:px-8">
        {/* Vision Section */}
        <div className="text-center mb-10">
          <h2 className="text-2xl md:text-4xl text-[#000000] font-semibold mb-12">Our Vision</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:px-6 lg:px-16">
            <img 
              src={vision} 
              alt="Vision" 
              className="w-4/5 h-auto object-cover rounded-lg mb-8 mx-auto md:mx-0"
            />
            <p className="text-sm md:text-[26px] text-[#313131] font-normal md:leading-relaxed tracking-wide flex items-start justify-center text-justify md:text-left lg:ml-[-80px]">
              We envision a world where cooking is a joyful journey for everyone, infused with confidence, creativity, and sustainability. By using top-quality, responsibly sourced ingredients and providing easy-to-follow culinary education, we're building a community of food lovers who appreciate the value of fresh, flavourful, and eco-friendly meals. Together, we're shaping a future where every dish brings happiness, satisfaction, and a positive impact on our planet.
            </p>
          </div>
        </div>

        {/* Mission Section */}
        <div className="text-center mb-10">
          <h2 className="text-2xl md:text-4xl text-[#000000] font-semibold mb-14">Our Mission</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:px-6 lg:px-16">
            <p className="text-sm md:text-[26px] text-[#313131] font-normal md:leading-relaxed tracking-wide flex items-start justify-center text-justify md:text-left order-2 md:order-1">
              At our core, we are dedicated to enriching your culinary journey. Our mission is to provide you with fresher, higher-quality ingredients while equipping you with the skills necessary to use them efficiently. We believe in empowering you to create flavorful dishes that nourish both body and soul. Additionally, we are committed to supporting local businesses, ensuring that our practices promote sustainability and contribute to the growth of our community.
            </p>
            <img 
              src={mission}
              alt="Mission" 
              className="w-4/5 h-auto object-cover rounded-lg mx-auto md:mx-0 order-1 lg:ml-[130px] md:order-2"
            />
          </div>
        </div>

        {/* Values Section */}
        <div className="text-center">
  <h2 className="text-2xl md:text-4xl text-[#000000] font-semibold mb-14">Our Values</h2>
  <div className="grid grid-cols-1 md:grid-cols-[auto,1fr] md:px-3 lg:gap-0 items-center">
    <img 
      src={value}
      alt="Values" 
      className="w-full ml-5 md:w-5/6 h-auto object-cover rounded-lg mx-auto md:mx-0 mb-4 lg:mb-0"
    />
    <p className="text-sm md:text-[26px] text-[#313131] font-normal md:leading-relaxed tracking-wide flex items-start justify-center text-justify md:text-left lg:ml-[-120px]">
      Our values, rooted in passion, drive us to empower you with fresher, higher quality ingredients and essential skills. We're customer-centric, valuing your feedback to create authentic experiences and delicious international recipes. Embracing diversity and integrity, we give back to our community with honesty and transparency, ensuring ethical business practices in every interaction.
    </p>
  </div>
</div>

      </section>
    </div>
  );
}

export default OurStory;
