import React from "react";
import blog2 from "../../../src/assets/images/blog2.png";
import blog3 from "../../../src/assets/images/blog3.png";
import blog4 from "../../../src/assets/images/blog4.png";
import blog5 from "../../../src/assets/images/blog5.png";
import blog6 from "../../../src/assets/images/blog6.png";
import blog7 from "../../../src/assets/images/blog7.png";
import { Link } from "react-router-dom";

interface Blogs {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  date: string;
  time: string;
}

const blogs: Blogs[] = [
  {
    id: 1,
    title: "Navratri Special: Savor the Rich Tradition of Gujarati Kadhi ",
    description:
      "As Navratri approaches, it’s time to indulge in dishes that not only nourish the body but also soothe the soul. One such dish, steeped in tradition and ",
    imageUrl: blog2,
    date: "November 14, 2022",
    time: "5 min",
  },
  {
    id: 2,
    title: "Navratri Special: Savor the Rich Tradition of Gujarati Kadhi ",
    description:"As Navratri approaches, it’s time to indulge in dishes that not only nourish the body but also soothe the soul. One such dish, steeped in tradition ",
    imageUrl: blog3,
    date: "November 14, 2022",
    time: "5 min",
  },
  {
    id: 3,
    title: "Navratri Special: Savor the Rich Tradition of Gujarati Kadhi ",
    description:
      "As Navratri approaches, it’s time to indulge in dishes that not only nourish the body but also soothe the soul. One such dish, steeped in tradition and ",
    imageUrl: blog4,
    date: "November 14, 2022",
    time: "5 min",
  },
  {
    id: 4,
    title: "Navratri Special: Savor the Rich Tradition of Gujarati Kadhi ",
    description:
      "As Navratri approaches, it’s time to indulge in dishes that not only nourish the body but also soothe the soul. One such dish, steeped in tradition and ",
    imageUrl: blog5,
    date: "November 14, 2022",
    time: "5 min",
  },
  {
    id: 5,
    title: "Navratri Special: Savor the Rich Tradition of Gujarati Kadhi ",
    description:
      "As Navratri approaches, it’s time to indulge in dishes that not only nourish the body but also soothe the soul. One such dish, steeped in tradition and ",
    imageUrl: blog6,
    date: "November 14, 2022",
    time: "5 min",
  },
  {
    id: 6,
    title: "Navratri Special: Savor the Rich Tradition of Gujarati Kadhi ",
    description:
      "As Navratri approaches, it’s time to indulge in dishes that not only nourish the body but also soothe the soul. One such dish, steeped in tradition and ",
    imageUrl: blog7,
    date: "November 14, 2022",
    time: "5 min",
  },
];

const BlogPost: React.FC = () => {
  return (
    <div className="container mx-auto pt-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {blogs.map((blog) => (
          <Link to= "/blogPost">
          <div key={blog.id} className="bg-white rounded-lg border border-1">
            <img
              src={blog.imageUrl}
              alt={blog.title}
              className="w-full h-100px object-cover rounded-t-lg mb-4"
            />

            <p className="px-5 mb-10">
              <div className="text-sm text-[#172B4D] my-2">
                <span>{blog.date}</span> | <span>{blog.time} read</span>
              </div>
              <h2 className="text-lg font-bold mb-5">{blog.title}</h2>
              <p className="text-sm">{blog.description}</p>
            </p>
          </div>
        </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogPost;
