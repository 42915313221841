import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../index.css";
import ProductSlider from "./imageSlider";
import StarFill from '../../assets/images/Star 6.png';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addUpdateItemAsync, getItemsByIdAsync } from "../../services/reducers/reciepeReducer";
import { capitalizeWords } from "../../utils/common";
import BlankStar from '../../assets/images/Star 7.png';

interface ProductProps {
  productDetail?: any;
}
const ProductCard: React.FC<ProductProps> = ({ productDetail }) => {
  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.receipe.itemsList);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryCode, setCountryCode] = useState<string | null>(null);

  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    const storedCode = localStorage.getItem('countryCode');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      setCountryCode(storedCode);
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // getRecipeDetails();
    }
  }, [selectedCountry]);
  const handleAdd = (productId: number, ingredient: any) => {
    if (userId) {
      //const newQuantity = (prev[productId] || 0) + 1;
      const request = {
        group: `Shop- -1`,
        country: countryCode,
        items: [
          {
            ...ingredient,
            selected: 1,
          },
        ],
      };

      dispatch(addUpdateItemAsync({ id: userId, request }))
      .then(() => {
        dispatch(getItemsByIdAsync({ id: userId, country: countryCode ?? '' }));
      });
    }

  };
 
  return (
    <div className="flex flex-col lg:flex-row lg:space-x-8 p-5 lg:p-0">
      {/* img box */}
      <div className="w-full lg:w-1/2 mb-5 lg:mb-0">
        <ProductSlider img={productDetail.image} />
      </div>

      {/* product details */}
      <div className="details w-full lg:w-full md:pl-5">
        <span className="font-medium text-left text-[20px] sm:text-[20px] md:text-[22px] lg:text-[24px] block">
          {capitalizeWords(productDetail.name)}
        </span>

        {/* five stars */}
        <div className="flex flex-row space-x-1 mt-2">
          {[...Array(5)].map((_, i) => (
            <img
              key={i}
              src={i < productDetail.rating ? StarFill : BlankStar}
              alt="Star Icon"
              style={{ width: 15, height: 15 }}
            />
          ))}
        </div>

        {/* price */}
        <div className="pt-5 flex items-center">
          <span className={`text-[16px] sm:text-[16px] lg:text-[20px] ${productDetail.revised_price > productDetail.price ? "text-[#B8B8B8] line-through mr-2 font-medium" : "text-[#313131] font-bold"}`}>
            ₹{productDetail.price}
          </span>
          <span className="text-[20px] sm:text-[20px] lg:text-[25px] font-bold text-[#313131] ml-4 lg:ml-10">
            {productDetail.revised_price > productDetail.price ? `₹${productDetail.revised_price}` : ""}
          </span>
        </div>

        {/* description */}
        <div>
          <p className="text-[14px] sm:text-[16px] lg:text-[16px] text-[#525C60] mt-3 lg:mt-2 mb-4 lg:mb-5">
            {productDetail.description}
          </p>
        </div>

        {/* responsive button */}
        <button className="border-2 border-[#0B6E27] rounded-md font-medium text-[16px] sm:text-[18px] lg:text-[20px] text-[#0B6E27] hover:bg-[#0B6E27] hover:text-white px-8 mt-2 sm:px-8 lg:px-14 py-2 sm:py-2.5 lg:py-3 mb-5 lg:mb-5" onClick={(e) => {
          e.stopPropagation();
          handleAdd(productDetail.id, productDetail);
        }}>
          Add
        </button>

        {/* <span className="text-[20px] mt-5 sm:text-[20px] lg:text-[18px] font-semibold block">
          Egg Dishes
        </span>
        <span className="text-[14px] sm:text-[14px] lg:text-[16px] text-[#9B9B9B] block">
          Easy Recipes with EGG
        </span> */}

        {/* recipe row with images */}
        {/* <div className="mt-5 flex justify-start space-x-5 overflow-x-auto">
          <a href="/">
            <div className="flex flex-col items-center">
              <img src="Rectangle 1684.png" alt="Shakshuka" />
              <h4 className="text-center mt-2 text-[12px] sm:text-[14px] lg:text-[16px] font-medium">
                Shakshuka
              </h4>
            </div>
          </a>
          <a href="/">
            <div className="flex flex-col items-center">
              <img src="Rectangle 1685.png" alt="Deviled Eggs" />
              <h4 className="text-center mt-2 text-[12px] sm:text-[14px] lg:text-[16px] font-medium">
                Deviled Eggs
              </h4>
            </div>
          </a>
          <a href="/">
            <div className="flex flex-col items-center">
              <img src="Rectangle 1686.png" alt="Egg Muffin" />
              <h4 className="text-center mt-2 text-[12px] sm:text-[14px] lg:text-[16px] font-medium">
                Egg Muffin
              </h4>
            </div>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default ProductCard;
