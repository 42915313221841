import React, { useState } from "react";
import Cuisine from "../../../src/assets/images/cuisine.png";
import Cuisine1 from "../../../src/assets/images/cuisine1.png";
import Cuisine2 from "../../../src/assets/images/cuisine2.png";
import Cuisine3 from "../../../src/assets/images/cuisine3.png";
import Cuisine4 from "../../../src/assets/images/cuisine4.png";
import Cuisine5 from "../../../src/assets/images/cuisine5.png";
import Cuisine6 from "../../../src/assets/images/cuisine6.png";
import Cuisine7 from "../../../src/assets/images/cuisine7.png";

type Cuisine = {
  name: string;
  imageSrc: string;
};

const cuisines: Cuisine[] = [
  { name: "Italian", imageSrc: Cuisine },
  { name: "Chinese", imageSrc: Cuisine1 },
  { name: "Korean", imageSrc: Cuisine2 },
  { name: "Thai", imageSrc: Cuisine3 },
  { name: "Japanese", imageSrc: Cuisine4 },
  { name: "Mexican", imageSrc: Cuisine5 },
  { name: "American", imageSrc: Cuisine6 },
  { name: "French", imageSrc: Cuisine7 },
  { name: "Italian", imageSrc: Cuisine },
  { name: "Chinese", imageSrc: Cuisine1 },
];

const CuisineSlider: React.FC = () => {
  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 8;

  const handleLeftClick = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleRightClick = () => {
    setStartIndex((prev) => Math.min(prev + 1, cuisines.length - itemsToShow));
  };

  return (
    <div className="w-full mb-4 p-6 md:p-12">
      <h2 className="text-xl md:text-2xl font-semibold mb-4 md:pl-10 font-sans">
        Which cuisine Recipe Kit you want to try next
      </h2>

      <div className="flex flex-col items-center">
        <div className="flex items-center justify-between w-full">
          <div className="flex space-x-4 py-4 overflow-x-scroll whitespace-nowrap scrollbar-hide">
            {cuisines.slice(startIndex, startIndex + itemsToShow).map((cuisine) => (
              <div
                key={cuisine.name}
                className="flex-shrink-0 md:w-40 text-center"
              >
                <img
                  src={cuisine.imageSrc}
                  alt={cuisine.name}
                  className="h-20 w-20 md:w-32 md:h-32 mx-4 md:mx-auto rounded-lg object-cover"
                />
                <p className="mt-2 text-md md:text-lg font-medium font-sans">
                  {cuisine.name}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <button
            className="bg-white shadow-md p-2 rounded-full"
            onClick={handleLeftClick}
            disabled={startIndex === 0}
          >
            &larr;
          </button>

          <button
            className="bg-white shadow-md p-2 rounded-full"
            onClick={handleRightClick}
            disabled={startIndex + itemsToShow >= cuisines.length}
          >
            &rarr;
          </button>
        </div>
      </div>
    </div>
  );
};

export default CuisineSlider;
