import React, { useEffect, useState } from 'react';
import explore from '../../../src/assets/images/explore.png';
import explore1 from '../../../src/assets/images/explore1.png';
import explore2 from '../../../src/assets/images/explore2.png';
import explore3 from '../../../src/assets/images/explore3.png';
import exploree from '../../../src/assets/images/exploree.png';
import exploree1 from '../../../src/assets/images/exploree1.png';
import exploree2 from '../../../src/assets/images/exploree2.png';
import exploree3 from '../../../src/assets/images/exploree3.png';
import Persona from '../Persona/Persona';
import { useNavigate } from 'react-router-dom';

const ExploreRecipe: React.FC = () => {
  const navigate = useNavigate();

  const handleView = (id:string) => {
    navigate(`/persona/${id}`);
  };
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  },[selectedCountry]);
  const persona = selectedCountry === 'India' ? explore : selectedCountry === 'UK' ? exploree : null;
  const persona1 = selectedCountry === 'India' ? explore1 : selectedCountry === 'UK' ? exploree1 : null;
  const persona2 = selectedCountry === 'India' ? explore2 : selectedCountry === 'UK' ? exploree2 : null;
  const persona3 = selectedCountry === 'India' ? explore3 : selectedCountry === 'UK' ? exploree3 : null;
  return (
    <div className="bg-white p-5 lg:p-12 mb-12 mt-0 lg:mt-12">
      <h2 className="text-md font-bold mb-8 font-sans lg:text-2xl">Explore Recipe Kit crafted just for you.</h2>
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 gap lg:gap-12">

        <div className="text-center mb-5 lg:mb-0 cursor-pointer" onClick={()=>handleView("1")}>
          {/* <img src={explore} alt="United Family" className="w-full h-full object-cover rounded-xl"/> */}
          {persona && (
          <img src={persona} alt="United Family" className="w-full h-full object-cover rounded-xl" />
        )}
          <p className="text-sm lg:text-lg font-semibold mt-2 lg:mt-2 font-sans">United Family</p>
        </div>

        <div className="text-center cursor-pointer mb-5 lg:mb-0" onClick={()=>handleView("2")}>
          {/* <img src={explore1}  alt="Busy Professional" className="w-full h-full object-cover rounded-xl"/> */}
          {persona1 && (
          <img src={persona1} alt="United Family" className="w-full h-full object-cover rounded-xl" />
        )}
          <p className="text-sm lg:text-lg font-semibold mt-2 lg:mt-2 font-sans">Working Professional</p>
        </div>

        <div className="text-center cursor-pointer lg:mb-0" onClick={()=>handleView("3")}>
          {/* <img src={explore2}  alt="Solution Seekers" className="w-full h-full object-cover rounded-xl"/> */}
          {persona2 && (
          <img src={persona2} alt="United Family" className="w-full h-full object-cover rounded-xl" />
        )}
          <p className="text-sm lg:text-lg font-semibold mt-2 lg:mt-2 font-sans">Solution Seekers</p>
        </div>

        <div className="text-center cursor-pointer lg:mb-0" onClick={()=>handleView("4")}>
          {/* <img src={explore3}  alt="Fitness Enthusiasts" className="w-full h-full object-cover rounded-xl"/> */}
          {persona3 && (
          <img src={persona3} alt="United Family" className="w-full h-full object-cover rounded-xl" />
        )}
          <p className="text-sm lg:text-lg font-semibold mt-2 lg:mt-2 font-sans">Fitness Enthusiasts</p>
        </div>
      </div>
    </div>
  );
};

export default ExploreRecipe;
