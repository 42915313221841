import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import FeatureSection from './FeatureSection'
import ExploreRecipe from './ExploreRecipe'
import CategoriesSection from './Categories'
import DelightReceipe from './DelightReceipe'
import HowItWorksSection from './HowToWork'
import ReceipeKit from './ReceipeKit'
import SaleSection from './Sale'
import CuisineSlider from './SwiperSlider'
import FAQ from './FAQ'
import GallerySection from "./SameDishes";
import TestimonialSlider from "./Slider";
import DiwaliHeader from './DiwaliHeader'

const HomePage = () => {
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [countryCode, setCountryCode] = useState<string | null>('IN');

  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    const storedCode = localStorage.getItem('countryCode');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      setCountryCode(storedCode);
    }
  }, []);
  return (
    <div>
      Welcome to {selectedCountry} that is  {countryCode}
      <Banner />
      <FeatureSection />
      <ExploreRecipe />
      <CategoriesSection />
      <HowItWorksSection />
      <DelightReceipe /> 
      {/* <ReceipeKit/> */}
      {/* <GallerySection/> */}
      <SaleSection />
      <CuisineSlider />
      <TestimonialSlider />
      <FAQ />
    </div>
  )
}

export default HomePage
