import React, { useEffect, useState } from "react";
import { createByRazorpayAsync, createOrderAsync } from "../../services/reducers/reciepeReducer";
import { useAppDispatch } from "../../hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import logo from "../../../src/assets/images/logo.png";


declare global {
  interface Window {
    Razorpay: any;
  }
}

interface RazorpayOptions {
  key: string;
  amount: number;
  currency: string;
  name: string;
  description: string;
  image: string;
  order_id: string;
  handler: (response: RazorpayResponse) => void;
  theme: {
    color: string;
  };
  prefill?: {
    name?: string;
    email?: string;
    contact?: string;
    method?: string;
  };
  method?: {
    upi?: boolean;
  };
}

interface RazorpayResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}

interface Props {
  cartTotal: any;
  price: any;
  cartIngredients: any;
  ipAddress: any;
  address:any;
  selectedAddress:any;
  detail:any;
}

const RazorpayPayment: React.FC<Props> = ({ cartTotal, price, cartIngredients, ipAddress, address, selectedAddress, detail }) => {
  const dispatch = useAppDispatch();

  const userId = localStorage.getItem("userId");

  const loadRazorpayScript = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    const scriptLoaded = await loadRazorpayScript();

    if (!scriptLoaded) {
      console.error("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      if(selectedAddress){
      const products = Object.keys(cartIngredients).flatMap((dishKey: string) => {
        const dishId = Number(dishKey.split("-")[1]);
        const ingredients = cartIngredients[dishKey];

        return ingredients.map((ingredient: any) => ({
          ingredient_id: ingredient.id,
          name: ingredient.name,
          recipe_id: dishId,
          quantity: ingredient.selected,
        }));
      });

      const requestBody = {
        order: {
          order_date: new Date().toISOString(),
          paid_date: new Date().toISOString(),
          phone: "",
          name: "",
          status: "pending",
          currency: "INR",
          ip_address: ipAddress,
          user_agent: "web",
          user_id: userId,
          shipping_address: address?.street_address,
          shipping_postcode: address?.postal_code,                
          shipping_city: address?.city,
          shipping_state: '',
          shipping_country: address?.country,
          shipping_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
          order_subtotal: parseFloat(cartTotal?.cart_value.toFixed(2)),
          order_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
          discount_total: detail.discount,
          coupon_id:detail.id
        },
        products: products,
      };

      const serializedRequest = JSON.stringify(requestBody);

      const response = await dispatch(createOrderAsync(serializedRequest)).unwrap();

      if (response.success && response.data && response.data.orderId) {
        const cartOrderId = response.data.orderId;
        console.log("Order created successfully. Order ID:", cartOrderId);

        const request = {
          currency: "INR",
          amount: price * 100,
          orderId: cartOrderId,
        };

        const result = await dispatch(createByRazorpayAsync(request));
        const orderId = unwrapResult(result).orderId;

        const options: RazorpayOptions = {
          key: "rzp_test_mLYpnlz3ljIJ1f",
          amount: request.amount,
          currency: "INR",
          name: "AnyFeast",
          description: "Test Transaction",
          image: logo,
          order_id: orderId,
          handler: function (response: RazorpayResponse) {
            console.log("Payment Success:", response);
          },
          theme: {
            color: "#C30F16",
          },
          prefill: {
            method: "upi",
          },
          method: {
            upi: true,
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      
      } else {
        console.error("Failed to create the order:", response.message);
      }
    }
    } catch (error) {
      console.error("Payment initiation failed:", error);
    }
  };

  return (
    <button onClick={handlePayment} className="btn btn-primary">
      Pay Now
    </button>
  );
};

export default RazorpayPayment;
