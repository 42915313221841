import React from 'react';

const AboutUs: React.FC = () => {

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-white">
      {/* Responsive container */}
      <div className="w-full max-w-2xl md:max-w-4xl lg:max-w-5xl px-4 py-4">
      <div className="relative w-full flex justify-center items-center">
  
  {/* Centered "About Us" Text */}
</div>

        <div className="mt-6 text-gray-800 space-y-4 font-normal">
          <p>
            Anyfeast is founded by Pankaj Kale, a self-confessed foodie, traveler, and technology enthusiast.
          </p>
          <p>
            Pankaj was born and raised in India in a middle-class family and instead of eating at fancy restaurants, Pankaj spent his childhood experimenting with different flavours and recipes.
          </p>
          <p>
            After completing his education in the UK, Pankaj travelled across the world in a quest for the best spices, flavours, and amazing recipes. Moving house so often in the UK meant having to go through the same hassle every time.
          </p>
          <p>
            With each move, Pankaj had to find new grocery stores, navigate new settings, and find authentic ingredients. Frustrated by the time it took to find high-quality ingredients without spending hours researching online.
          </p>
          <p>
            Pankaj developed "Anyfeast" as a newer, better way to try new cuisines from the comfort of your home.
          </p>
        </div>

        <div className="mt-8 space-y-2">
          {/* Buttons with right arrows */}
          <button
            className="w-full flex justify-between items-center bg-[#FBFBFB] text-gray-900 px-4 py-3 rounded-lg focus:outline-none hover:bg-gray-200"
            onClick={() => window.location.href = "https://anyfeast.in/terms-conditions/"}
          >
            <span className="font-medium">Terms & Conditions</span>
            <svg className="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>

          <button
            className="w-full flex justify-between items-center bg-[#FBFBFB] text-gray-900 px-4 py-3 rounded-lg focus:outline-none hover:bg-gray-200"
            onClick={() => window.location.href = "https://anyfeast.in/privacy-policy/"}
          >
            <span className="font-medium" >Privacy Policy</span>
            <svg className="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>


          <div className="w-full flex justify-center items-center px-4 py-3">
            <span className="text-gray-900 font-medium">Version 1.2</span>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AboutUs;
