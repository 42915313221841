import React, { useState } from "react";
import Delight from "../../../src/assets/images/delight.png";
import Delight1 from "../../../src/assets/images/delight1.png";
import Delight2 from "../../../src/assets/images/delight2.png";
import Delight3 from "../../../src/assets/images/delight3.png";
import Delight4 from "../../../src/assets/images/delight4.png";
import Delight5 from "../../../src/assets/images/delight5.png";
import Delight6 from "../../../src/assets/images/delight6.png";
import Right from "../../../src/assets/images/right.png";

const categories = [
  { name: "Breakfast", img: Delight },
  { name: "Lunch", img: Delight1 },
  { name: "Dinner", img: Delight2 },
  { name: "Snacks", img: Delight3 },
  { name: "Dessert", img: Delight4 },
  { name: "Salad", img: Delight5 },
  { name: "Soups", img: Delight6 },
  { name: "Drinks", img: Delight },
  { name: "Shake", img: Delight1 },
];

interface DelightTrialProps {
  showRecipeKit?: boolean;
}

const DelightTrial: React.FC<DelightTrialProps> = ({ showRecipeKit = true }) => {
  const [selectedRecipe, setSelectedRecipe] = useState("Lunch");

  const handleCategoryClick = (categoryName: string) => {
    setSelectedRecipe(categoryName);
  };

  return (
    <div className="px-6 sm:px-6 lg:px-8 max-w-7xl lg:mx-12 py-10">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold font-sans">Daily Delight Recipe Kit</h2>
        <button className="sm:text-[14px] lg:text-[18px] md:text-l  text-[#C30F16] border-b-2 border-[#C30F16] font-sans flex items-center pb-1 md:pb-2">
          View All
          <img src={Right} className="h-6 w-6 ml-2" alt="Right arrow" />
        </button>
      </div>

      <div className="flex overflow-x-auto scrollbar-hide lg:space-x-8 space-x-3"> 
  {categories.map((category) => (
    <div
      key={category.name}
      className="text-center flex-shrink-0 cursor-pointer"
      onClick={() => handleCategoryClick(category.name)}
    >
      <img
        src={category.img}
        alt={category.name}
        className="w-20 h-20 md:w-24 md:h-24 lg:w-32 lg:h-32 object-cover mt-2 rounded-lg"
      />
      <p className={`mt-2 text-sm md:text-lg lg:text-xl ${selectedRecipe === category.name ? "text-[#C30F16]" : "text-black"}`}>
        {category.name}
      </p>
    </div>
  ))}
</div>

    </div>
  );
};

export default DelightTrial;
