import { useEffect, useState } from "react";
import p1 from "../../../src/assets/images/p1.png";
import p2 from "../../../src/assets/images/p2.png";
import p3 from "../../../src/assets/images/p3.png";
import p4 from "../../../src/assets/images/p4.png";
import p5 from "../../../src/assets/images/p5.png";
import p6 from "../../../src/assets/images/p6.png";
import p7 from "../../../src/assets/images/p7.png";
import p9 from "../../../src/assets/images/p-9.png";
import { useAppDispatch, useAppSelector } from "../../hooks";
import StepProgress from "./StepProgress";
import SpecialOffersSection from "./SpecialOffer";
import RazorpayPayment from "../CheckOut/CheckOutWithRozarpay";
import CheckoutForm from "../CheckOut/CheckOut";
import { Elements } from "@stripe/react-stripe-js";
import { Constants } from "../../services/sharedService/constants";
import { loadStripe } from "@stripe/stripe-js";
import { addUpdateItemAsync, createOrderAsync, getAdressByUserIdAsync, getItemsByIdAsync, getSubscriptionInfoAsync } from "../../services/reducers/reciepeReducer";
import axios from "axios";
import { createCod } from "../../services/sharedService/AuthService";
import AddressModal from "../CheckOut/AddressModal";
import { useNavigate } from "react-router-dom";
import CouponPopUp from "./Coupons";

const PaymentSection = () => {
    const [selectedUPI, setSelectedUPI] = useState<string | null>(null);
    const [ipAddress, setIpAddress] = useState<string | null>(null);
    const dispatch = useAppDispatch();
    // const cartIngredients = useAppSelector((state) => state.cart);itemsList
    const [currentStep, setCurrentStep] = useState(1);
    const stripePromise = loadStripe(Constants?.loadStripe);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddressModalOpen, setAddressModalOpen] = useState(false);
    //const cartIngredients = useAppSelector((state) => state.receipe.itemsList);
    const [cartIngredients, seCartIngredients] = useState<any>([]);
    const [cartAddress, setCartAddress] = useState<any>([]);
    const [extra, setExtra] = useState<any>([]);
    const [cartTotal, seCartTotal] = useState<any>([]);
    const userId = localStorage.getItem("userId");
    let itemCount = 0;
    const keys = Object.keys(cartIngredients);
    const firstKey = keys[0];
    const result = firstKey?.split('-')[0];
    const ids = Number(firstKey?.split('-')[1]);
    if (keys.length > 0) {
        itemCount = cartIngredients[keys[0]].length;
    }
    const [selectedAddress, setSelectedAddress] = useState(null);
    const address = cartAddress.find((addr: any) => addr.address_id === selectedAddress);
    const carts = useAppSelector((state) => state.receipe.itemsList);
    const navigate = useNavigate();
    const [isCouponOpen, setIsCouponOpen] = useState(false);
    const [detail, setDetail] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const handleConfirm = () => {
        navigate('/profile');
        window.scrollTo(0, 0); 
      };
      
    
    //Coupon Navigation
    const handleCouponOpen = () => {
        setIsCouponOpen(true);
    }
    const handleCouponClose = () => {
        setIsCouponOpen(false);
    }


    useEffect(() => {
        const country = "IN";
        if (userId) {
            dispatch(getItemsByIdAsync({ id: userId, country })).then((res) => {
                if (res.payload) {
                    seCartIngredients(res.payload?.data?.cart?.cart || {});
                    seCartTotal(res.payload?.data?.cart || 0);
                    setExtra(res.payload?.data?.extras || []);
                }
            });
        }
    }, [userId]);

    useEffect(() => {
        getAddress();
    }, []);
    useEffect(() => {

    }, [detail]);
    useEffect(() => {
        if (userId) {
            dispatch(getSubscriptionInfoAsync(userId)).then((res) => {
                if (res.payload) {
                    const subscriptionDateTimestamp = new Date(res.payload.data.subscription_date).getTime();
                    const currentDateTimestamp = res.payload.data.current_date;
                    const status = res.payload.data.subscription_status
                    console.log(subscriptionDateTimestamp, "subscriptionDateTimestamp")
                    // Calculate the difference in days
                    const differenceInTime = currentDateTimestamp - subscriptionDateTimestamp;
                    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

                    if (differenceInDays > 3650) {
                        console.log("Difference in days is greater than 3650.");
                        navigate(`/subscription`);
                    }

                    // if (subscriptionDateTimestamp > currentDateTimestamp) {
                    //     console.log("Subscription date is greater than current date.");
                    //   } else if (status === 0 && subscriptionDateTimestamp < currentDateTimestamp) {
                    //     console.log("Current date is greater than subscription date.");
                    //     navigate(`/subscription`);
                    //   }
                }

            });
        }
    }, []);

    const getAddress = () => {
        if (userId) {
            dispatch(getAdressByUserIdAsync(userId)).then((res) => {
                if (res.payload) {
                    setCartAddress(res.payload?.data);
                }
            });
        }
    }
    const paymentApps = [
        { name: "Google Pay", image: p1 },
        { name: "Phone Pay", image: p2 },
        { name: "Cred UPI", image: p3 },
        { name: "Paytm", image: p4 },

    ];

    const otherApps = [
        { name: "Cash On Delivery", image: p7 },
        // { name: "AnyFeast Wallet", image: p8 },

    ];
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countryCode, setCountryCode] = useState<string | null>(null);
  
    useEffect(() => {
      const storedCountry = localStorage.getItem('selectedCountry');
      const storedCode = localStorage.getItem('countryCode');
      if (storedCountry) {
        setSelectedCountry(storedCountry);
        setCountryCode(storedCode);
      }
    }, []);
  
    useEffect(() => {
      if (selectedCountry) {
        // getRecipeDetails();
      }
    }, [selectedCountry]);
    const handleUpdateItemQuantity = (dishKey: string, ingredient: any, change: number) => {
        if (!userId) {
            console.error("User ID is not available");
            return;
        }
        const newQuantity = ingredient.selected + change;
        if (newQuantity < 0) return;
        const dishId = Number(dishKey.split("-")[1]);

        const dishName = dishKey.split("-")[0];
        const request = {
            group: `${dishName}-${dishId}`,
            country: countryCode,
            items: [
                {
                    ...ingredient,
                    selected: newQuantity
                }
            ]
        };

        dispatch(addUpdateItemAsync({ id: userId, request }))
            .then((res) => {
                if (res.payload) {
                    const updatedCart = { ...cartIngredients };
                    updatedCart[dishKey] = updatedCart[dishKey].map((item: any) =>
                        item.id === ingredient.id ? { ...item, selected: newQuantity } : item
                    );
                    seCartIngredients(updatedCart);
                }
            })
            .catch((err) => console.error("Error updating item:", err));
    };
    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await axios.get("https://api.ipify.org?format=json");
                setIpAddress(response.data.ip);
            } catch (error) {
                console.error("Error fetching IP address:", error);
            }
        };
        fetchIpAddress();
    }, [])

    const handleUPISelection = (appName: string) => {
        setSelectedUPI(appName);
        createCodAsync();

    };

    const handleView = () => {
        navigate(`/subscription`);
    };
    const createCodAsync = async () => {
        if (selectedAddress) {

            const products = Object.keys(cartIngredients).flatMap((dishKey: string) => {
                const dishId = Number(dishKey.split('-')[1]);
                const ingredients = cartIngredients[dishKey];

                return ingredients.map((ingredient: any) => ({
                    ingredient_id: ingredient.id,
                    name: ingredient.name,
                    recipe_id: dishId,
                    quantity: ingredient.selected,
                }));
            });

            const requestBody = {
                order: {
                    order_date: new Date().toISOString(),
                    paid_date: new Date().toISOString(),
                    phone: '',
                    name: "",
                    status: 'pending',
                    currency: 'INR',
                    ip_address: ipAddress,
                    user_agent: 'web',
                    user_id: userId,
                    shipping_address: address?.street_address,
                    shipping_postcode: address?.postal_code,
                    shipping_city: address?.city,
                    shipping_state: '',
                    shipping_country: address?.country,
                    shipping_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
                    order_subtotal: parseFloat(cartTotal?.cart_value.toFixed(2)),
                    order_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
                    discount_total: detail.discount,
                    coupon_id: detail.id
                },
                products: products,
            };

            const createOrderResponse = await dispatch(createOrderAsync(JSON.stringify(requestBody))).unwrap();

            if (createOrderResponse.success && createOrderResponse.data && createOrderResponse.data.orderId) {
                const cartOrderId = createOrderResponse.data.orderId;
                console.log('Order created successfully. Order ID:', cartOrderId);

                const codResult = createCod(cartOrderId)
            }
        }
    };

    console.log(detail, "12")

    const handleAddressSelect = (address: any) => {
        setSelectedAddress(address);
    };

    const calculateTotal = () => {
        const discount = detail?.discount_type === "percent"
            ? -(cartTotal?.cart_value * detail.discount) / 100
            : -detail.discount;

        const extraCharges = extra.reduce((acc: any, curr: any) => acc + curr.charge, 0);

        return (cartTotal?.cart_value || 0) + (discount || 0) + extraCharges;
    };

    useEffect(() => {
        setTotal(calculateTotal().toFixed(2));
    }, [cartTotal, detail, extra]);
    
    return (
        <div>
            <div className="mt-8">
                <StepProgress currentStep={currentStep} />
            </div>
            <div className="flex gap-6 px-8 py-6">
                {/* Left Section: Payment and Address */}
                <div className="w-2/3 space-y-6">
                    {/* Shipping Address */}
                    {/* <div className="bg-white p-4 rounded-lg border shadow-custom-red font-sans">
                        <h3 className="text-lg pb-4">Shipping Address</h3>

                        <button className="text-[#202020] mt-2" onClick={()=> setAddressModalOpen(!isAddressModalOpen)}>+ Add New Address</button>
                    </div> */}
                    <div className="bg-white p-4 rounded-lg border shadow-custom-red font-sans"> {/*//shadow-custom-red*/}
                        <h3 className="text-lg pb-8">Select Delivery Address</h3>

                        {/* Example address cartAddress */}
                        {cartAddress.map((address: any, index: number) => (
                            <div className="flex items-center mb-8" key={address.address_id}>
                                <input
                                    type="radio"
                                    name="address"
                                    className="mr-2"
                                    checked={selectedAddress === address.address_id}
                                    onChange={() => handleAddressSelect(address.address_id)}
                                />
                                <label className="text-[#202020]">
                                    {address.flat_no ? `${address.flat_no}, ` : ''}
                                    {address.street_address ? `${address.street_address}, ` : ''}
                                    {address.city}, {address.state_province}, {address.postal_code}, {address.country}
                                </label>
                            </div>
                        ))}


                        {/* Add New Address Button */}
                        <button
                            className="flex items-center text-[#C30F16] bg-[#FFC4C433] p-3 rounded-lg text-sm font-semibold"
                            onClick={() => setAddressModalOpen(!isAddressModalOpen)}
                        >
                            <span className="mr-1 text-2xl">+</span>
                            Add New Address
                        </button>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-custom-red font-sans"> {/*//shadow-custom-red*/}
                        <p className="text-[#313131] text-md font-medium">Your cart contains heavier items.  For bulky orders, our team may need to contact you to arrange delivery after purchase. We appreciate your understanding.</p>
                    </div>
                    {/* Payment Methods */}
                    <div className="bg-white p-4 rounded-lg border shadow-custom-red font-sans"> {/*//shadow-custom-red*/}
                        <h3 className="text-lg font-semibold text-[#000000] mt-2">Pay Using UPI App</h3>
                        <div className="flex flex-col py-4">
                            {/* {paymentApps.map((app) => (
                                <label
                                    key={app.name}
                                    className="flex items-center justify-between border-dashed border-b p-4"
                                >
                                    <div className="flex items-center">
                                        <img src={app.image} alt={app.name} className="w-6 h-6 mr-4" />
                                        <span className="text-[#000000]">{app.name}</span>
                                    </div>
                                    <input
                                        type="radio"
                                        name="upi"
                                        value={app.name}
                                        checked={selectedUPI === app.name}
                                        onChange={(e) => setSelectedUPI(e.target.value)}
                                        className="text-[#0B6E27] form-radio h-4 w-4 border-gray-300 focus:ring-0 checked:text-[#0B6E27]"
                                    />
                                </label>
                            ))} */}
                            <div className="flex items-center justify-between px-4">
                                {/* Add Another UPI ID */}
                                <span className="text-[#000000] font-sans"> GPay - UPI</span>
                                <RazorpayPayment cartTotal={cartTotal} price={cartTotal?.cart_value} cartIngredients={cartIngredients} ipAddress={ipAddress} address={address} selectedAddress={selectedAddress} detail={detail} />
                                {/* <img src={p5} alt="Add UPI ID" className="w-6 h-6" onClick={handleCreateOrder} /> */}
                            </div>

                        </div>

                        <div className="flex flex-col mt-2">
                            <h3 className="text-lg font-semibold text-[#000000] font-sans">Pay Using Card </h3>

                            <div className="flex items-center justify-between px-4 py-4" >
                                <div className="flex items-center">
                                    <img src={p6} alt="Add UPI ID" className="w-6 h-6 mr-4" />
                                    <span className="text-[#000000] font-sans">Credit/Debit Card</span>
                                </div>
                                <img src={p5} alt="Add UPI ID" className="w-6 h-6 cursor-pointer" onClick={() => setIsModalOpen(true)} />
                            </div>
                        </div>

                        {/* Card Payment and Other Payment Methods */}
                        <div className="bg-white font-sans mt-2">
    <h3 className="text-lg font-semibold text-[#000000]">Other Payment Options</h3>
    <div className="flex flex-col">
        <div className="flex items-center justify-between px-4 py-4" >
            <div className="flex items-center">
                <img src={p7} alt="Add UPI ID" className="w-6 h-6 mr-4" />
                <span className="text-[#000000] font-sans">Cash On Delivery</span>
            </div>
            <input
    type="radio"
    name="paymentOption"
    value="Cash On Delivery"
    onChange={() => {
        createCodAsync(); 
        handleConfirm();
    }}
    className="form-radio h-5 w-5 text-green-600 border-gray-300 focus:ring-0 checked:bg-green-600"
/>

        </div>
        {/* Uncomment and adapt for other options if needed */}
        {/* {otherApps.map((app) => (
            <label
                key={app.name}
                className="flex items-center justify-between border-dashed border-b px-4 py-4"
            >
                <div className="flex items-center">
                    <img src={app.image} alt={app.name} className="w-6 h-6 mr-4" />
                    <span className="text-[#000000]">{app.name}</span>
                </div>
                <input
                    type="radio"
                    name="paymentOption"
                    value={app.name}
                    checked={selectedUPI === app.name}
                    onChange={() => handleUPISelection(app.name)}
                    className="form-radio h-5 w-5 text-green-600 border-gray-300 focus:ring-0 checked:bg-green-600"
                />
            </label>
        ))} */}
    </div>
</div>

                    </div>
                </div>

                {/* Right Section: Cart Items */}
                <div className="w-1/3">
                    <div className="bg-white rounded-lg border shadow-custom-red font-sans mb-4"> {/*//shadow-custom-red*/}
                        <div className="bg-[#440508] text-white text-center py-2 rounded-t-lg">
                            <span className="text-sm font-medium">
                                Unlock Total Benefits Upto 20% off on this order
                            </span>
                        </div>

                        {/* Premium Section */}
                        <div className="flex justify-between items-center p-4 border-dashed border-b font-sans">
                            <div>
                                <h2 className="text-red-700 font-bold text-lg">AnyFeast Premium</h2>
                            </div>
                            <div onClick={() => handleView()}>
                                <a href="#" className="text-red-700 font-medium flex items-center">
                                    Know more
                                    <svg className="ml-2" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1.26074L6 5.99992L1 10.7391" stroke="#C30F16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            </div>
                        </div>

                        {/* Benefits Section */}
                        <div className="p-4 font-sans">
                            <h3 className="font-bold text-lg underline">Benefits</h3>
                            <ul className="list-disc pl-5 mt-2">
                                <li>20% discount on all meal kit orders.</li>
                                <li>Free Delivery.</li>
                            </ul>
                        </div>

                        {/* See All Link */}
                        <div className="flex justify-end p-4 font-sans">
                            <a href="#" className="text-red-700 font-medium flex items-center">
                                See All
                                <svg className="ml-2" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1.26074L6 5.99992L1 10.7391" stroke="#C30F16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg border shadow-custom-red font-sans cursor-pointer" onClick={handleCouponOpen}> {/*//shadow-custom-red*/}
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <img src={p9} alt="Add UPI ID" className="w-6 h-6 mr-4" />
                                <h3 className="text-lg">View coupons & Offers</h3>
                            </div>
                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6 6L1 11" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <CouponPopUp isOpen={isCouponOpen} onClose={handleCouponClose} setDetail={setDetail} />


                    <div className="bg-white p-4 rounded-lg border shadow-custom-red mt-4"> {/*//shadow-custom-red*/}
                        {Object.keys(cartIngredients).map((dishKey: string) => {
                            const dishName = dishKey.split("-")[0];
                            const dishId = Number(dishKey.split("-")[1]);
                            const ingredients = cartIngredients[dishKey];

                            return (
                                <div key={dishKey} className="mb-6">
                                    <h3 className="text-lg font-semibold mb-4 text-center">
                                        {ingredients.length} items for {dishName}
                                    </h3>

                                    <ul className="space-y-6">
                                        {ingredients.map((ingredient: any, index: number) => (
                                            <li key={index} className="flex items-center justify-between">
                                                <div className="w-16 h-16">
                                                    <img
                                                        src={ingredient.image}
                                                        alt={ingredient.name}
                                                        className="w-full h-full object-cover rounded-lg"
                                                    />
                                                </div>

                                                <div className="flex-1 mx-4 pl-8">
                                                    <h3 className="text-lg font-semibold text-[#253D4E] pb-2">{ingredient.name}</h3>
                                                    <p className="text-[#828282] font-sans pb-2">Pack of {ingredient.quantity}</p>
                                                    <div className="flex items-center space-x-2">
                                                        <span className="text-[#3BB77E] text-lg font-bold pb-2 font-sans">
                                                            ₹{ingredient.price}
                                                        </span>
                                                        {ingredient.revised_price > 0.00 && (
                                                            <span className="text-[#ADADAD] line-through pb-2 font-sans">
                                                                ₹{ingredient.revised_price}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Quantity Selector */}
                                                <div className="flex items-center bg-[#0B6E27] text-white rounded-lg p-2 font-sans">
                                                    <button
                                                        onClick={() => handleUpdateItemQuantity(dishKey, ingredient, -1)}
                                                        className="px-2 py-1"
                                                        aria-label="Decrease"
                                                    >
                                                        -
                                                    </button>

                                                    <span className="px-4">{ingredient.selected}</span>
                                                    <button
                                                        onClick={() => handleUpdateItemQuantity(dishKey, ingredient, 1)}
                                                        className="px-2 py-1"
                                                        aria-label="Increase"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>

                    <div className="bg-white p-6 rounded-lg border shadow-custom-red mt-4 font-sans"> {/*//shadow-custom-red*/}
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-[#787878]">Subtotal</span>
                            <span className="text-[#313131]">₹{cartTotal?.cart_value
                            }</span>
                        </div>

                        {/* <div className="flex justify-between items-center mb-4">
                            <span className="text-[#787878]">Delivery Fee</span>
                            <div className="flex items-center">
                                <span className="text-[#000000] line-through mr-2">₹200</span>
                                <span className="text-[#0B6E27] font-semibold">FREE</span>
                            </div>
                        </div> */}
                        {extra.map((extra: any, index: number) => (
                            <div key={index} className="flex justify-between items-center mb-4">
                                <span className="text-[#787878]">{extra.name}</span>
                                <span className="text-[#000000]">₹{extra.charge}</span>
                            </div>
                        ))}
                        {detail && (
                            <div className="flex justify-between items-center mb-4">
                                <span className="text-[#787878]">Coupon discount</span>
                                <span className="text-[#000000]">
                                    ₹
                                    {detail.discount_type === "percent"
                                        ? (cartTotal?.cart_value * detail.discount) / 100
                                        : detail.discount}
                                </span>
                            </div>
                        )}
                        {detail ? (
                            <div className="flex justify-between items-center font-semibold text-xl mt-6">
                                <span className="text-[#313131]">Total</span>
                                <span className="text-[#313131] font-bold">₹ {total}</span>
                            </div>
                        ) : (
                            <div className="flex justify-between items-center font-semibold text-xl mt-6">
                                <span className="text-[#313131]">Total</span>
                                <span className="text-[#313131] font-bold">₹{cartTotal?.cart_value + extra.reduce((acc: any, curr: any) => acc + curr.charge, 0)}</span>
                            </div>
                        )}
                    </div>

                </div>

            </div>
            {/* <div>
                <SpecialOffersSection />
            </div> */}
            {isAddressModalOpen && (
                <AddressModal isOpen={isAddressModalOpen} onClose={() => setAddressModalOpen(false)} getAddress={getAddress} />
            )}
            <Elements stripe={stripePromise}>
                {isModalOpen && (
                    <CheckoutForm isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} cartTotal={cartTotal} price={cartTotal?.cart_value} cartIngredients={cartIngredients} ipAddress={ipAddress} address={address} selectedAddress={selectedAddress} detail={detail} />
                )}
            </Elements>
        </div>
    );
};

export default PaymentSection;
