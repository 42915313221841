import React, { useEffect, useState } from "react";
import discountsign from "../../assets/images/discountsign.png";
import caratUp from "../../assets/images/caratUp.png";
import { getAllCouponAsync, redeemCouponAsync } from "../../services/reducers/reciepeReducer";
import { useAppDispatch } from "../../hooks";

interface CouponProps {
  isOpen: boolean;
  onClose: () => void;
  setDetail: any;
}
const CouponPopUp: React.FC<CouponProps> = ({ isOpen, onClose, setDetail }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const [getCoupons, setGetCoupons] = useState<any>([]);
  const userId = localStorage.getItem("userId");
  const [couponCode, setCouponCode] = useState('');

  useEffect(() => {
    const country = "IN";
    dispatch(getAllCouponAsync(country)).then((res) => {
      if (res.payload) {
        setGetCoupons(res.payload?.data);
      }
    });
  }, []);

  const couponCodes = getCoupons.map((coupon: any) => ({
    code: coupon.code,
    discount: coupon.discount_type === "percent"
    ? `${parseFloat(coupon.discount).toFixed(0)}%`
    : `₹${parseFloat(coupon.discount).toFixed(0)}`,
    description: coupon.description
  }));

  const handleApply = (couponName: string) => {

    const request = {
      name: couponName,
      uid: userId
    };

    dispatch(redeemCouponAsync(request)).then((res) => {
      if (res.payload) {
        setDetail(res.payload.data);
        onClose();
      }
    });
  };

  if (!isOpen) return null;

  const toggleDetails = (index: number) => {
    setIsDetailsOpen((prev) => (prev === index ? null : index));
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center oveflow-hidden pt-20">
      <div className="bg-white rounded-lg px-8 pb-8 w-1/3 h-4/5 overflow-hidden">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold mt-8">Coupons</h2>
          <button onClick={onClose} className="font-bold">
            x
          </button>
        </div>
        <div className="flex items-center my-5">
          <input
            type="text"
            className="text-xs border border-[#AAAAAA] rounded-lg p-2 w-full text-stone-400"
            placeholder="Enter Coupon Code here"
            value={couponCode}
            onChange={(e) => {
              setCouponCode(e.target.value);
            }}
          />
          <div className="ml-2 text-stone-400 font-semibold" onClick={() => handleApply(couponCode)}>APPLY</div>
        </div>
        <div>
          <center className="mt-8 mb-3 font-semibold font-sans">
            OFFERS FOR YOU
          </center>
          {/* coupon container */}
          <div className="h-96 overflow-y-auto scrollbar-hide">
            {couponCodes.map((couponCodes: any, index: number) => (
              <div key={index} className="py-3 rounded-lg border shadow-custom-red font-sans mb-10">
                <div>
                  <h5 className="px-8 py-2 font-semibold font-sans">{couponCodes.code}</h5>
                  <div className="px-8 flex flex-row gap-2 my-3 items-center">
                    <div>
                      <img src={discountsign} />
                    </div>
                    <div className="font-sans text-[#0B6E27]">

                      Save {couponCodes.discount} with this code
                    </div>
                  </div>
                  <div className="flex flex-col px-8">
                    <div
                      className="cursor-pointer my-3 text-[#5E5E5E] flex items-center space-x-1"
                      onClick={() => toggleDetails(index)}
                    >
                      View Details
                      <img src={caratUp} className={`ml-2 ${isDetailsOpen === index ? "rotate-0" : "rotate-180"
                        }`} />
                    </div>
                    {isDetailsOpen === index && (
                      <div className="mt-2 ml-5">
                        <ul className="list-disc text-[#313131]">
                          <li className="mb-2">{couponCodes.description}.</li>
                          {/* <li className="mb-2">
                        Applicable on selected items only.
                      </li>
                      <li className="mb-2">One use per customer.</li>
                      <li className="mb-2">
                        Cannot be combined with other offers or discounts.
                      </li> */}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="my-3 border-t border-dashed border-stone-400 flex items-center justify-center">
                    <button className="text-[#0B6E27] text-[20px] pt-2" onClick={() => handleApply(couponCodes.code)}>APPLY</button></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CouponPopUp;
