import { useEffect } from "react";
import Header from "./components/Home/Header";
import Container from "./components/Container";
import TopHeader from "./components/Home/TopHeader";
import Footer from "./components/Home/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./components/Home/HomeIndex";
import PersonaIndex from "./components/Persona/PersonaIndex";
import DetailIndex from "./components/ReceipeDetail/DetailIndex";
import Cart from "./components/ReceipeDetail/Cart";
import ShopScreens from "./components/Shop/ShopScreens";
import ProductPage from "./components/ShopDetail/productPage";
import BlogPage from "./components/Blog/blogPage";
import ProfilePage from "./components/Profile/profileIndex";
import Blog from "./components/Blog/blog";
import Aboutus from "./components/AboutUs/Aboutus";
import OurStory from "./components/OurStory/OurStory";
import BlogPost from "./components/Blog/blogPost";
import ShopExplore from "./components/Shop/shopExplore";
import PricingPlans from "./components/Subscription/Subscription";
import SearchBar from "./components/Home/SeachBar";
import DeliveryDetails from "./components/Profile/Order/orderDetails";
import RefundScreens from "./components/Profile/Order/orderCancellation";



const AppContainer = () => {
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    console.log(accessToken, "accessToken");
  }, []);

  let location = useLocation();
  console.log(location.pathname);

  return (
    <Container>
      <div>
        {/* <Router> */}

        {location.pathname != "/.well-known/assetlinks.json" &&
          <div>
            <TopHeader />
            <Header />
          </div>
        }

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/shop" element={<ShopScreens />} />
          <Route path="/shop/cuisine/:cuisine" element={<ShopExplore/>} />
          <Route path="/persona/:id" element={<PersonaIndex />} />

          <Route path="/detail/:id" element={<DetailIndex />} />

          <Route path="/cart" element={<Cart />} />

          <Route path="/product/:id" element={<ProductPage />} />

          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/order/:id" element={<DeliveryDetails />} />

          <Route path="/order-cancel/:id" element={<RefundScreens />} />

          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blogPost" element={<Blog />} />
          {/* <Route path="/.well-known/assetlinks.json" element={<AssetLink />} /> */}

          <Route path="/Aboutus" element={<Aboutus />} />

          <Route path="/OurStory" element={<OurStory />} />

          <Route path="/subscription" element={<PricingPlans />} />

        </Routes>
        {location.pathname != "/.well-known/assetlinks.json" &&
          <Footer />
        }
        {/* <ToastContainer /> */}
        {/* </Router> */}
      </div>
    </Container>
  );
};

export default AppContainer;
