import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonial from "../../../src/assets/images/testimonial.png";

const testimonials = [
  {
    image: Testimonial,
    name: "Customer 1",
    date: "Apr 30, 2024",
    title: "Elevating Every Occasion with AnyFeast",
    text: "Thanks to AnyFeast, I've kissed meal planning stress goodbye! With fresh ingredients delivered to my door and easy-to-follow recipes, cooking has become a joy. From gourmet dishes to quick meals, it's convenience and quality rolled into one. Highly recommend.",
  },
  {
    image: "https://via.placeholder.com/100",
    name: "Customer 2",
    date: "Sept 09, 2024",
    title: "Game Changer for Busy People",
    text: "AnyFeast has transformed my cooking experience. The ingredients are always fresh, and the recipes are easy to follow. It's a game-changer for busy people like me.",
  },
];

const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "gray", borderRadius: "100%" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "gray", borderRadius: "100%" }}
      onClick={onClick}
    />
  );
};

const TestimonialCarousel: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerPadding: "120px",
          className: "center",
        },
      },
    ],
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl md:text-3xl font-bold text-center mb-8 font-sans text-[#313131]">
        What Our Customers Say About Us
      </h2>
      <div className="slider-container">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-4">
              <div className="bg-white rounded-lg shadow-lg flex flex-col md:flex-row items-center md:items-start md:min-h-[300px] min-h-[600px]">
                <div className="md:w-1/3">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="rounded-l-lg object-cover w-full h-auto max-h-64"
                  />
                </div>
                <div className="md:w-2/3 ml-0 md:ml-6 text-[#525252] p-6 md:p-8 font-sans flex flex-col justify-center">
                  <div className="mb-4 md:mb-10">
                    <p className="text-sm mb-2">{testimonial.date}</p>
                    <h3 className="text-lg md:text-xl font-bold mb-2">
                      {testimonial.title}
                    </h3>
                  </div>
                  <p>{testimonial.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
